import React, { useState, useEffect } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Location from "./Location";

const GuarantorForm = ({ data, bikeType, onChange }) => {

   // Initial guarantor structure
   const initialGuarantors = {
    insurer_one: {
      guarantorPosition: "",
      phoneNumber: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nationalId: "",
      occupation: "",
      province: "",
      district: "",
      sector: "",
      village: "",
      uploadedFile: null,
    },
    insurer_two: {
      guarantorPosition: "",
      phoneNumber: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nationalId: "",
      occupation: "",
      province: "",
      district: "",
      sector: "",
      village: "",
      uploadedFile: null,
    },
  };

  const [guarantorsData, setGuarantorsData] = useState(initialGuarantors);
 

  const isElectricBike = bikeType === 'ELECTRIC';

  useEffect(() => {
    if (data) {
      const insurerOnePosition = data.insurer_one?.guarantorPosition || "insurer_one";
      const insurerTwoPosition = data.insurer_two?.guarantorPosition || "insurer_two";
  
      const storedFileOne = localStorage.getItem(`${insurerOnePosition}`);
      const storedFileTwo = localStorage.getItem(`${insurerTwoPosition}`);

      console.log(insurerOnePosition,'uploaded',storedFileOne)
      // Ensure we only take the relevant fields from `data`
      const filteredData = {
        insurer_one: {
          ...data.insurer_one,
          uploadedFile: storedFileOne ? { uid: "-1", name:`${insurerOnePosition}`, url: storedFileOne } : null,
        },
        insurer_two: {
          ...data.insurer_two,
          uploadedFile: storedFileTwo ? { uid: "-1", name: `${insurerTwoPosition}`, url: storedFileTwo } : null,
        },
      };

      setGuarantorsData(filteredData);
    }
  }, [data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Only update if data is different
      if (JSON.stringify(guarantorsData) !== JSON.stringify(data)) {
        onChange(guarantorsData);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [guarantorsData, data, onChange]);

  const handleInputChange = (insurerType, e) => {
    const { name, value } = e.target;
    setGuarantorsData((prevData) => ({
      ...prevData,
      [insurerType]: {
        ...prevData[insurerType],
        [name]: value,
      },
    }));
  };

  const validateFile = (file) => {
    const isPDF = file.type === "application/pdf";
    const isSmallEnough = file.size / 1024 / 1024 < 1;

    if (!isPDF) message.error(`${file.name} is not a PDF file!`);
    if (!isSmallEnough) message.error(`${file.name} exceeds the 1MB size limit!`);
    return isPDF && isSmallEnough;
  };


  // convert file 
  const convertFileToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) => console.error("Error converting file:", error);
  };

  
  const handleFileChange = (insurerType, info) => {
    const { file } = info;
    const guarantorPositionName = guarantorsData[insurerType]?.guarantorPosition || insurerType;

    console.log('guarantorPosition',guarantorPositionName);

    if (file.status !== "removed") {
      convertFileToBase64(file.originFileObj || file, (base64File) => {
        localStorage.setItem(`${guarantorPositionName}`, base64File);
  
        setGuarantorsData((prevData) => ({
          ...prevData,
          [insurerType]: {
            ...prevData[insurerType],
            uploadedFile: file.originFileObj || file,
          },
        }));
  
        message.success(`${file.name} has been added successfully.`);
      });
    } else {
      localStorage.removeItem(`${insurerType}_uploadedFile`);
      setGuarantorsData((prevData) => ({
        ...prevData,
        [insurerType]: {
          ...prevData[insurerType],
          uploadedFile: null,
        },
      }));
    }
  };

  // const handleFileChange = (insurerType, info) => {

  //   const { file } = info;
  //   if (file.status !== "removed") {
  //     localStorage.setItem(`${guarantorsData.guarantorPosition}`, file);
  //     setGuarantorsData(prevData => ({
  //       ...prevData,
  //       [insurerType]: {
  //         ...prevData[insurerType],
  //         uploadedFile: file.originFileObj || file
  //       }
  //     }));
  //     message.success(`${file.name} has been added successfully.`);
  //   } else {
  //     localStorage.removeItem(`${insurerType}`);
  //     setGuarantorsData(prevData => ({
  //       ...prevData,
  //       [insurerType]: {
  //         ...prevData[insurerType],
  //         uploadedFile: null
  //       }
  //     }));
  //   }
  // };

  const renderFileUploadLabel = (position) => {
    switch (position) {
      case "MOTARD":
        return "Injiza carte jaune";
      case "UMUKOZI":
        return "Injiza contract";
      case "UMUCURUZI":
        return "Injiza icyemezo cy'ubucuruzi";
      default:
        return "";
    }
  };

  const updatedLocation = (insurerType, location) => {
    setGuarantorsData(prevData => ({
      ...prevData,
      [insurerType]: {
        ...prevData[insurerType],
        ...location
      }
    }));
  };

  const renderGuarantorForm = (insurerType, title) => {
    const guarantorData = guarantorsData[insurerType];
    const fileList = guarantorData.uploadedFile ? [guarantorData.uploadedFile] : [];

    return (
      <div className=" rounded-lg p-4 mb-4">
        <h3 className="text-lg font-semibold mb-4">{title}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium">
              Umwishingizi wawe ari mukihe cyiciro
            </label>
            <select
              name="guarantorPosition"
              value={guarantorData.guarantorPosition}
              onChange={(e) => handleInputChange(insurerType, e)}
              className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            >
              <option value="" disabled>Hitamo</option>
              <option value="UMUKOZI">UMUKOZI</option>
              <option value="MOTARD">MOTARD</option>
              <option value="UMUCURUZI">UMUCURUZI</option>
              <option value="MOTO Y'AMASHANYARAZI">MOTO Y'AMASHANYARAZI</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium">
              Nimero ya telefone
            </label>
            <input
              name="phoneNumber"
              type="text"
              placeholder="078..."
              value={guarantorData.phoneNumber}
              onChange={(e) => handleInputChange(insurerType, e)}
              className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            />
          </div>
        </div>

        {guarantorData.guarantorPosition &&
          guarantorData.guarantorPosition !== "MOTO Y'AMASHANYARAZI" && (
            <div className="w-full border flex items-center justify-between mt-3 rounded-md p-2">
              <label className="block text-sm font-medium mx-3">
                {renderFileUploadLabel(guarantorData.guarantorPosition)}
              </label>
              <Upload
                fileList={fileList}
                customRequest={({ onSuccess }) => onSuccess("ok")}
                beforeUpload={(file) => {
                  if (!validateFile(file)) return Upload.LIST_IGNORE;
                  return false;
                }}
                onChange={(info) => handleFileChange(insurerType, info)}
              >
                <Button className="text-white" icon={<UploadOutlined />}>
                  Choose File
                </Button>
              </Upload>
            </div>
          )}

        <div className="grid grid-cols-3 gap-4 mt-4">
          {["firstName", "middleName", "lastName"].map((field) => (
            <div key={field}>
              <label className="block text-sm font-medium">
                {field === "firstName"
                  ? "Izina ribanza"
                  : field === "middleName"
                  ? "Izina ryo hagati"
                  : "Irindi zina"}
              </label>
              <input
                name={field}
                type="text"
                placeholder={
                  field === "firstName"
                    ? "Izina ribanza"
                    : field === "middleName"
                    ? "Izina ryo hagati"
                    : "Irindi zina"
                }
                value={guarantorData[field]}
                onChange={(e) => handleInputChange(insurerType, e)}
                className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
              />
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-sm font-medium">
              Nimero y'irangamuntu
            </label>
            <input
              name="nationalId"
              type="number"
              placeholder="Nimero y'irangamuntu"
              value={guarantorData.nationalId}
              onChange={(e) => handleInputChange(insurerType, e)}
              className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Icyo akora</label>
            <input
              name="occupation"
              type="text"
              placeholder="Icyo akora"
              value={guarantorData.occupation}
              onChange={(e) => handleInputChange(insurerType, e)}
              className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            />
          </div>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium">
            Aho umwishingizi atuye
          </label>
          <Location
            province={guarantorData.province}
            district={guarantorData.district}
            sector={guarantorData.sector}
            cell={guarantorData.cell}
            village={guarantorData.village}
            onLocationChange={(location) => updatedLocation(insurerType, location)}
          />
        </div>
      </div>
    );
  };

  return (
    <form className="space-y-4">
      {renderGuarantorForm("insurer_one", "Umwishingizi wa mbere")}
      {isElectricBike && renderGuarantorForm("insurer_two", "Umwishingizi wa kabiri")}
    </form>
  );
};

export default GuarantorForm;
