import React, { useState, useEffect } from "react";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";

const ImageUploadProviderImage = ({ uploadedImage, onImageSelect }) => {
  const [fileList, setFileList] = useState([]);

  // Convert base64 to File-like object
  const base64ToFile = (base64, filename, mimeType) => {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: mimeType });
  };

  // Initialize fileList when uploadedImage prop changes
  useEffect(() => {
    const processImage = async (imageData) => {
      try {
        let actualFile = imageData;
        
        // If it's a stored object with thumbUrl
        if (imageData.thumbUrl && !(imageData instanceof File)) {
          actualFile = base64ToFile(
            imageData.thumbUrl,
            imageData.name || "uploaded_image",
            imageData.type || 'image/png'
          );
        }

        if (actualFile) {
          const objectUrl = actualFile instanceof File 
            ? URL.createObjectURL(actualFile)
            : imageData.thumbUrl;

          const newFile = {
            uid: imageData.uid || "-1",
            name: imageData.name || "Uploaded Image",
            status: "done",
            url: objectUrl,
            originFileObj: actualFile,
          };

          setFileList([newFile]);
          return () => URL.revokeObjectURL(objectUrl);
        }
      } catch (error) {
        console.error("Error processing image:", error);
        setFileList([]);
      }
    };

    if (uploadedImage) {
      processImage(uploadedImage);
    } else {
      setFileList([]);
    }
  }, [uploadedImage]);

  const onChange = async ({ fileList: newFileList })  => {
    setFileList(newFileList);

    // Prepare the file for storage and pass to parent
    if (onImageSelect && newFileList.length > 0) {
      const selectedFile = newFileList[0];
      
      // Create storable object
      const storableFile = {
        uid: selectedFile.uid,
        name: selectedFile.name,
        type: selectedFile.type,
        size: selectedFile.size,
        thumbUrl: selectedFile.thumbUrl || 
          (selectedFile.originFileObj instanceof File 
            ? await convertFileToBase64(selectedFile.originFileObj)
            : selectedFile.url),
      };

      onImageSelect(storableFile);
    } else {
      onImageSelect(null);
    }
  };

  // Helper to convert File to base64
  const convertFileToBase64 = (file) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  const onPreview = async (file) => {
    let src = file.url;
    if (!src && file.originFileObj) {
      src = await convertFileToBase64(file.originFileObj);
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <ImgCrop rotationSlider modalProps={{ forceRender: true }}>
      <Upload
        className="text-white"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        maxCount={1}
        beforeUpload={() => false}
      >
        {fileList.length < 1 && "+ Upload"}
      </Upload>
    </ImgCrop>
  );
};

export default ImageUploadProviderImage;