import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncCells,
  fetchAsyncDistricts,
  fetchAsyncSectors,
  fetchAsyncVillages,
  getAllCells,
  getAllDistricts,
  getAllProvinces,
  getAllSectors,
  getAllVillages,
} from "../../redux/transactions/UntappedSlice";

function Location({ province, district, sector, cell, village, onLocationChange }) {
  const dispatch = useDispatch();
  const allProvinces = useSelector(getAllProvinces);
  const allDistricts = useSelector(getAllDistricts);
  const allSectors = useSelector(getAllSectors);
  const allCells = useSelector(getAllCells);
  const allVillages = useSelector(getAllVillages);

  // Handle input changes and notify parent
  const handleChange = (e) => {
    const { name, value } = e.target;
    onLocationChange({ ...{ province, district, sector, cell, village }, [name]: value });
  };

  // Fetch location data when props change
  useEffect(() => {
    if (province) dispatch(fetchAsyncDistricts({ province }));
  }, [province, dispatch]);

  useEffect(() => {
    if (district) dispatch(fetchAsyncSectors({ district }));
  }, [district, dispatch]);

  useEffect(() => {
    if (sector) dispatch(fetchAsyncCells({ sector }));
  }, [sector, dispatch]);

  useEffect(() => {
    if (cell) dispatch(fetchAsyncVillages({ cell }));
  }, [cell, dispatch]);

  return (
    <form>
     
      <div className="mb-4  grid md:grid-cols-2 grid-cols-1 gap-2">
        {/* Province */}
        <div>
          <label className="block text-sm font-medium">Intara/Province</label>
          <select name="province" value={province} onChange={handleChange} className="w-full px-3 py-2 border rounded-lg bg-gray-300 text-black">
            <option value="">Hitamo Intara/Province</option>
            {allProvinces?.map((p) => (
              <option key={p.id} value={p.id}>{p.name}</option>
            ))}
          </select>
        </div>

        {/* District */}
        <div>
          <label className="block text-sm font-medium">Akarere/District</label>
          <select name="district" value={district} onChange={handleChange} className="w-full px-3 py-2 border rounded-lg bg-gray-300 text-black">
            <option value="">Akarere/District</option>
            {allDistricts?.map((d) => (
              <option key={d.id} value={d.id}>{d.name}</option>
            ))}
          </select>
        </div>

        {/* Sector */}
        <div>
          <label className="block text-sm font-medium">Umurenge/Sector</label>
          <select name="sector" value={sector} onChange={handleChange} className="w-full px-3 py-2 border rounded-lg bg-gray-300 text-black">
            <option value="">Umurenge/Sector</option>
            {allSectors?.map((s) => (
              <option key={s.id} value={s.id}>{s.name}</option>
            ))}
          </select>
        </div>

        {/* Cell */}
        <div>
          <label className="block text-sm font-medium">Akagari/Cell</label>
          <select name="cell" value={cell} onChange={handleChange} className="w-full px-3 py-2 border rounded-lg bg-gray-300 text-black">
            <option value="">Akagari/Cell</option>
            {allCells?.map((c) => (
              <option key={c.id} value={c.id}>{c.name}</option>
            ))}
          </select>
        </div>

        {/* Village */}
        <div>
          <label className="block text-sm font-medium">Umudugudu/Village</label>
          <select name="village" value={village} onChange={handleChange} className="w-full px-3 py-2 border rounded-lg bg-gray-300 text-black">
            <option value="">Umudugudu/Village</option>
            {allVillages?.map((v) => (
              <option key={v.id} value={v.id}>{v.name}</option>
            ))}
          </select>
        </div>
      </div>
    </form>
  );
}

export default Location;
