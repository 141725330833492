import React, { useState, useEffect } from "react";
import Location from "./Location";
import { motion } from "framer-motion";
function BasicInformation({ data, basicInformation }) {
  const stepVariants = {
    initial: { opacity: 0, x: 50 }, // Start faded out and slightly to the right
    animate: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.3, ease: "easeOut" },
    }, // Fade in and slide in
    exit: { opacity: 0, x: -50, transition: { duration: 0.3, ease: "easeIn" } }, // Fade out and slide left
  };
  const [formData, setFormData] = useState({
    province: "",
    district: "",
    sector: "",
    village: "",
    licenseNumber: "",
    issuedDate: "",
    maritalStatus: "",
    houseOwnership: "",
    duration: { type: "", value: "" },
    rent_amount: "",
  });
  // Convert "true" to true, "false" to false
  const handlehouseOwnershipChange = (event) => {
    const { name, value } = event.target;

    let parsedValue;
    if (name === "houseOwnership") {
      parsedValue = value === "true";
    } else {
      parsedValue = value;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
    }));
  };
  // Update state only if incoming `data` changes
  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(formData)) {
      setFormData({
        ...data,
        duration: data.duration || { type: "", value: "" },
      });
    }
  }, [data]);

  // Notify parent only if `formData` changes
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (JSON.stringify(formData) !== JSON.stringify(data)) {
        basicInformation(formData);
      }
    }, 500); // Debounce updates

    return () => clearTimeout(timeout);
  }, [formData, data, basicInformation]);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle duration change separately
  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      duration: {
        ...(prevData.duration || { type: "", value: "" }), // ✅ Ensure duration exists
        [name]: value,
      },
    }));
  };

  // Update formData with the new location data
  const updatedLocation = (location) => {
    setFormData((prevData) => ({
      ...prevData,
      ...location,
    }));
  };

  return (
    <form className="">
      <div>
        <label className="block text-sm font-medium">Aho utuye</label>
        <Location
          province={formData.province}
          district={formData.district}
          sector={formData.sector}
          cell={formData.cell}
          village={formData.village}
          onLocationChange={updatedLocation}
        />
      </div>

      <div className="flex justify-between items-center">
        <div className="mr-4 w-4/5">
          <label className="block text-sm font-medium mb-1">
            Nimero y’icyangombwa cyo Gutwara
          </label>
          <input
            type="number"
            name="licenseNumber"
            value={formData.licenseNumber}
            onChange={handleChange}
            className="w-full px-3 focus:outline-none py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div className="w-1/3">
          <label className="block text-sm font-medium mb-1">
            Igihe yatangiwe
          </label>
          <input
            type="date"
            name="issuedDate"
            value={formData.issuedDate}
            onChange={handleChange}
            className="w-full px-3 focus:outline-none py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="mr-4 w-4/5">
          <label className="block text-sm font-medium mb-1">
            Irangamimerere
          </label>
          <select
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleChange}
            className="w-full px-3 py-2 border focus:outline-none dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          >
            <option value="" disabled>
              Hitamo
            </option>
            <option value="INGARAGU">INGARAGU</option>
            <option value="NARASHATSE">NARASHATSE</option>
            <option value="TWARATANDUKANYE">TWARATANDUKANYE</option>
          </select>
        </div>
        <div className="w-1/3">
          <label className="block text-sm font-medium mb-1">
            Inzu mutuyemo niyanyu?
          </label>
          <select
            name="houseOwnership"
            value={formData.houseOwnership}
            onChange={handlehouseOwnershipChange}
            className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          >
            <option value="" disabled>
              Hitamo
            </option>
            <option value={true}>Yego</option>
            <option value={false}>Oya</option>
          </select>
        </div>
      </div>

      {formData.houseOwnership === true && (
        <motion.div
          key="step1"
          variants={stepVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <label className="block text-sm font-medium mb-1">
            Niba mukodesha, mwishyura angahe?
          </label>
          <input
            type="number"
            name="rent_amount"
            placeholder="mwishyura angahe "
            value={formData.rent_amount}
            onChange={handleChange}
            className="w-full px-3 focus:outline-none py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </motion.div>
      )}

      <div className="flex justify-between items-center">
        <div className="w-full">
          <label className="block text-sm font-medium mb-1">
            Muyimazemo igihe kingana iki?
          </label>
          <div className="flex w-full">
            <select
              name="type"
              value={formData.duration && formData.duration.type}
              onChange={handleDurationChange}
              className="w-2/4 mr-1 focus:outline-none px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            >
              <option value="" disabled>
                Hitamo Igihe
              </option>
              <option value="IMYAKA">IMYAKA</option>
              <option value="AMEZI">AMEZI</option>
              <option value="IMINSI">IMINSI</option>
            </select>
            <input
              type="number"
              name="value"
              value={formData.duration && formData.duration.value}
              onChange={handleDurationChange}
              placeholder="Uzuza hano umubare w'igihe"
              className="w-full px-3 focus:outline-none py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default BasicInformation;
