import React, { useState, useEffect } from "react";
import DashboardLayout from "../DashboardLayout";
import { Pagination, Spin, message, Button, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPenToSquare,
  faBookReader,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchAsyncApplicantsApplications,
  loadingAlldata,
  getAllApplicantsApplications,
} from "../../../redux/transactions/UntappedSlice";
import EditDrawer from "./EditApplicationDrawer";
import DownloadButton from "./DownloadButton";

function JaliApplicants() {
  const allpaginatedApplication = useSelector(getAllApplicantsApplications);
  console.log("allpaginatedApplication", allpaginatedApplication);
  const isLoading = useSelector(loadingAlldata);
  const dispatch = useDispatch();

  const [searchQuery, setsearchQuery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [statuses, setStatuses] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [applicationDetails, setViewApplicationDetails] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [hasAttemptedDownload, setHasAttemptedDownload] = useState(false);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setsearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDownloadPdf = async (applicantId) => {
    setIsDownloading(true);
    setDownloadError(null);
    setHasAttemptedDownload(true);
    try {
      const response = await fetch(
        `https://applicationbackend.jalikoi.rw/api/v1/pdf/applicants/${applicantId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );

      console.log("response", response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `applicant_${applicantId}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownloading(false);
    } catch (error) {
      console.error("Failed to download PDF", error);
      setIsDownloading(false);
      setDownloadError(error);
    }
  };

  const formatYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${year}-${month}-${day}`;
  };

  const [triggerSubmission, setTriggerSubmission] = useState(false);

  const saveChanges = () => {
    setTriggerSubmission(true);
    console.log(triggerSubmission);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleViewRider = (applicant) => {
    setViewApplicationDetails(applicant);
    setDrawerVisible(true);
  };

  const handleEditRider = (applicant) => {
    setIsEditMode(true);
    setViewApplicationDetails(applicant);
    // setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setViewApplicationDetails(null);
    setIsEditMode(false);
  };
  const paginatedData = allpaginatedApplication?.slice(startIndex, endIndex);
  console.log("paginatedData", paginatedData);
  useEffect(() => {
    dispatch(fetchAsyncApplicantsApplications({ searchInput }));
  }, [dispatch, searchInput]);

  useEffect(() => {
    if (hasAttemptedDownload) {
      if (isDownloading) {
        messageApi.open({
          type: "loading",
          content: "Downloading...",
          duration: 0,
        });
      } else if (downloadError) {
        messageApi.destroy();
        message.error("Download failed!");
      } else {
        messageApi.destroy();
        message.success("Download complete!");
      }
    }
  }, [isDownloading, downloadError, hasAttemptedDownload, messageApi]);

  return (
    <DashboardLayout>
      {contextHolder}
      <Spin spinning={isLoading} size="large" tip="Loading applicatants">
        <div className="mb-4">
          <input
            placeholder="Search by First name or Last name"
            value={searchQuery}
            onChange={handleSearch}
            className="w-full max-w-md"
          />
        </div>
        <table className="w-full text-left text-base text-gray-700 dark:text-gray-900 border">
          <thead className="text-xs text-gray-700 uppercase dark:text-gray-400 border-b">
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-black">
              <th scope="col" className="px-2 py-4">
                First Name
              </th>
              <th scope="col" className="px-2 py-4">
                Last Name
              </th>
              <th scope="col" className="px-2 py-4">
                DOB
              </th>
              <th scope="col" className="px-2 py-4 uppercase">
                MOTObike Type
              </th>
              <th scope="col" className="px-2 py-4">
                NID
              </th>
              <th scope="col" className="px-2 py-4">
                Phone
              </th>
              <th scope="col" className="px-2 py-4">
                Created at
              </th>
              <th scope="col" className="px-2 py-4">
                Actions{" "}
              </th>
              <th scope="col" className="px-2 py-4">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData &&
              paginatedData.map((applicant) => {
                const createdDate = new Date(applicant.created_at);
                const isNew =
                  new Date().getTime() - createdDate.getTime() <
                  24 * 60 * 60 * 1000;
                const applicantStatus =
                  statuses[applicant.id] || (isNew ? "New" : "Old");
                return (
                  <tr
                    key={applicant.id}
                    className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-black"
                  >
                    <td className="border-r ">
                      <div className="capitalize pl-3">
                        {applicant.first_name}
                      </div>
                    </td>
                    <td className="px-2 border-r  ">
                      <div className="capitalize">{applicant.last_name}</div>
                    </td>
                    <td className="px-2 border-r  ">
                      {new Date(applicant.dob).toLocaleDateString()}
                    </td>
                    <td className="px-2 border-r  ">
                      {applicant?.moto_leasing.type}
                    </td>
                    <td className="px-2 border-r  ">{applicant.nid}</td>
                    <td className="px-2 border-r  ">{applicant.phone}</td>
                    <td className="px-2 border-r  ">
                      {formatYYYYMMDD(applicant.created_at)}
                    </td>
                    <td className="px-2 border-r  ">
                      <div className="flex justify-around items-center">
                        {/* <div
                          onClick={() => handleDownloadPdf(applicant.id)}
                          className="px-3 cursor-pointer  rounded-md "
                        >
                          <FontAwesomeIcon
                            icon={faDownload}
                            size="1x"
                            className="cursor-pointer text-[#23419B] "
                          />
                        </div> */}
                        <DownloadButton  applicantData={applicant} />
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size="1x"
                          className="cursor-pointer text-[#23419B] mx-5"
                          onClick={() => handleEditRider(applicant)}
                        />
                        <div
                          onClick={() => handleViewRider(applicant)}
                          className="px-3 cursor-pointer  rounded-md "
                        >
                          <FontAwesomeIcon
                            icon={faBookReader}
                            size="1x"
                            className="cursor-pointer text-[#23419B] "
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-2 border-r  ">
                      <span
                        className={`px-2 py-1 rounded text-black ${
                          applicantStatus === "New"
                            ? "text-white bg-green-700 text-sm px-3"
                            : "text-black bg-yellow-500 text-sm px-4"
                        }`}
                      >
                        {applicantStatus}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex justify-end bg-white p-3 rounded-md items-center mt-4">
          {allpaginatedApplication && (
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={allpaginatedApplication.length}
              onChange={handlePageChange}
            />
          )}
        </div>
      </Spin>

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <span>Edit Application Details</span>
            <div className="flex justify-end  py-4">
              <Button
                type="primary"
                onClick={() => saveChanges()}
                loading={triggerSubmission}
                className="bd-[#23419B] bg-[#23419B] hover:bg-[#23419B] hover:text-white"
              >
                Save Changes {triggerSubmission}
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={closeDrawer}
        visible={isEditMode}
        width={640}
      >
        <EditDrawer
          applicationDetails={applicationDetails}
          triggerSubmission={triggerSubmission}
          onSubmissionComplete={() => setTriggerSubmission(false)}
        />
      </Drawer>

      <Drawer
        title="Application Details"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        width={800}
      >
        {applicationDetails && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="">
              <div>
                <h2 className="text-xl font-bold text-gray-900 mb-4">
                  Applicant Photo
                </h2>
                {applicationDetails.files &&
                applicationDetails.files.length > 0 ? (
                  applicationDetails.files
                    .filter((file) => {
                      const fileName = file.split("/").pop();
                      const fileType = fileName.split("-")[3];
                      return fileType === "PHOTO";
                    })
                    .map((file, index) => {
                      const filePath = file;
                      return (
                        <div key={index} className="flex flex-col items-center">
                          <img
                            className="h-64 w-64 object-cover rounded-lg shadow-md"
                            alt={`${applicationDetails.first_name} ${applicationDetails.last_name}`}
                            src={filePath}
                          />
                          <p className="mt-2 text-sm text-gray-500">
                            {applicationDetails.first_name}{" "}
                            {applicationDetails.last_name}
                          </p>
                        </div>
                      );
                    })
                ) : (
                  <div className="flex items-center justify-center h-64 bg-gray-100 rounded-lg">
                    <p className="text-gray-500">No photo available</p>
                  </div>
                )}
              </div>

              <div>
                <h2 className="text-xl font-bold text-gray-900 mb-4">
                  Address
                </h2>
                <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                  {[
                    {
                      label: "Province",
                      value:
                        applicationDetails.residence.village.cells.sector
                          .district.province.name,
                    },
                    {
                      label: "District",
                      value:
                        applicationDetails.residence.village.cells.sector
                          .district.name,
                    },
                    {
                      label: "Sector",
                      value:
                        applicationDetails.residence.village.cells.sector.name,
                    },
                    {
                      label: "Cell",
                      value: applicationDetails.residence.village.cells.name,
                    },
                    {
                      label: "Village",
                      value: applicationDetails.residence.village.name,
                    },
                  ].map((item, index) => (
                    <div key={index} className="flex">
                      <span className="font-semibold w-24 text-gray-700">
                        {item.label}:
                      </span>
                      <span className="text-gray-600">{item.value}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className=" flex justify-between py-2 w-full" >
                <div className=" ">
                  <h2 className="text-xl font-bold text-gray-900 mb-4">
                    Personal Information
                  </h2>
                  <div className="">
                    <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                      {[
                        {
                          label: "First Name",
                          value: applicationDetails.first_name,
                        },
                        {
                          label: "Last Name",
                          value: applicationDetails.last_name,
                        },
                        {
                          label: "Date of Birth",
                          value: new Date(
                            applicationDetails.dob
                          ).toLocaleDateString(),
                        },
                        {
                          label: "National ID",
                          value: applicationDetails.nid,
                        },
                        {
                          label: "Phone Number",
                          value: applicationDetails.phone,
                        },
                        {
                          label: "Marital Status",
                          value: applicationDetails.status,
                        },
                      ].map((item, index) => (
                        <div key={index} className="flex">
                          <span className="font-semibold w-32 text-gray-700">
                            {item.label}:
                          </span>
                          <span className="text-gray-600">{item.value}</span>
                        </div>
                      ))}
                    </div>
                    {applicationDetails.partner && (
                      <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                        <h3 className="font-semibold text-lg text-gray-800 mb-2">
                          Partner Information
                        </h3>
                        {[
                          {
                            label: "First Name",
                            value: applicationDetails.partner.first_name,
                          },
                          {
                            label: "Last Name",
                            value: applicationDetails.partner.last_name,
                          },
                          {
                            label: "Phone",
                            value: applicationDetails.partner.phone,
                          },
                          {
                            label: "Occupation",
                            value: applicationDetails.partner.occupation,
                          },
                        ].map((item, index) => (
                          <div key={index} className="flex">
                            <span className="font-semibold w-32 text-gray-700">
                              {item.label}:
                            </span>
                            <span className="text-gray-600">{item.value}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {/* moto_leasing */}
                <div className="">
                  <h2 className="text-xl font-bold text-gray-900 mb-4">
                    Moto: {applicationDetails.moto_leasing.type}
                  </h2>
                  <div className="">
                    <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Debts:
                        </span>
                        <span className="text-gray-600">
                          {applicationDetails.debts ? "YES" : "NO"}{" "}
                        </span>
                      </div>

                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Driving License:
                        </span>
                        <span className="text-gray-600">
                          {" "}
                          {applicationDetails.driving_licency}{" "}
                        </span>
                      </div>

                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Driving Licency Issued Date:
                        </span>
                        <span className="text-gray-600">
                          {applicationDetails.driving_licency_issued_date
                            ? new Date(
                                applicationDetails.driving_licency_issued_date
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })
                            : "N/A"}
                        </span>
                      </div>

                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Home owner:
                        </span>
                        <span className="text-gray-600">
                          {" "}
                          {applicationDetails.home_ownership
                            ? "YES"
                            : "NO"}{" "}
                        </span>
                      </div>

                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Rent :
                        </span>
                        <span className="text-gray-600">
                          {" "}
                          {applicationDetails.rent_duration}{" "}
                        </span>
                      </div>

                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Experience
                        </span>
                        <span className="text-gray-600">
                          {" "}
                          {applicationDetails.moto_experience}{" "}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Daily Income:
                        </span>
                        <span className="text-gray-600">
                          {" "}
                          {applicationDetails.daily_income}{" "}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          Motorcycle owner:
                        </span>
                        <span className="text-gray-600">
                          {" "}
                          {applicationDetails.moto_ownership}{" "}
                        </span>
                      </div>
                    </div>

                    {applicationDetails.insurer_two && (
                      <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                        <h3 className="font-semibold text-lg text-gray-800 mb-2">
                          Second Guarantor
                        </h3>
                        {[
                          {
                            label: "First Name",
                            value: applicationDetails.insurer_two.first_name,
                          },
                          {
                            label: "Last Name",
                            value: applicationDetails.insurer_two.last_name,
                          },
                          {
                            label: "Phone",
                            value: applicationDetails.insurer_two.phone,
                          },
                          {
                            label: "National ID",
                            value: applicationDetails.insurer_two.identity,
                          },
                          {
                            label: "Occupation",
                            value: applicationDetails.insurer_two.occupation,
                          },
                          {
                            label: "Village",
                            value:
                              applicationDetails.insurer_two.residence.village
                                .name,
                          },
                          {
                            label: "Cell",
                            value:
                              applicationDetails.insurer_two.residence.village
                                .cells.name,
                          },
                          {
                            label: "Sector",
                            value:
                              applicationDetails.insurer_two.residence.village
                                .cells.sector.name,
                          },
                          {
                            label: "District",
                            value:
                              applicationDetails.insurer_two.residence.village
                                .cells.sector.district.name,
                          },
                          {
                            label: "Province",
                            value:
                              applicationDetails.insurer_two.residence.village
                                .cells.sector.district.province.name,
                          },
                        ].map((item, index) => (
                          <div key={index} className="flex">
                            <span className="font-semibold w-32 text-gray-700">
                              {item.label}:
                            </span>
                            <span className="text-gray-600">{item.value}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {/* <div className="mt-9">
                  <span className="text-lg font-bold text-gray-900 mb-">
                    Uploaded files
                  </span>
                  {applicationDetails.files &&
                  applicationDetails.files.length > 0 ? (
                    <div className="flex flex-wrap gap-4">
                      {applicationDetails.files.map((file, index) => {
                        const fileName = file.split("/").pop();
                        const fileType = fileName.split("-")[3].split(".")[0];
                        return (
                          <div
                            key={index}
                            className="flex flex-col items-center"
                          >
                            <a
                              href={file}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              Open {fileType}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>No files uploaded.</div>
                  )}
                </div> */}
                </div>
              </div>
              <div className="md:col-span-2">
                <h2 className="text-xl font-bold text-gray-900 mb-4">
                  Guarantor Information
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                    <h3 className="font-semibold text-lg text-gray-800 mb-2">
                      First Guarantor
                    </h3>
                    {[
                      {
                        label: "First Name",
                        value: applicationDetails.insurer_one.first_name,
                      },
                      {
                        label: "Last Name",
                        value: applicationDetails.insurer_one.last_name,
                      },
                      {
                        label: "Phone",
                        value: applicationDetails.insurer_one.phone,
                      },
                      {
                        label: "National ID",
                        value: applicationDetails.insurer_one.identity,
                      },
                      {
                        label: "Occupation",
                        value: applicationDetails.insurer_one.occupation,
                      },
                      {
                        label: "Village",
                        value:
                          applicationDetails.insurer_one.residence.village.name,
                      },
                      {
                        label: "Cell",
                        value:
                          applicationDetails.insurer_one.residence.village.cells
                            .name,
                      },
                      {
                        label: "Sector",
                        value:
                          applicationDetails.insurer_one.residence.village.cells
                            .sector.name,
                      },
                      {
                        label: "District",
                        value:
                          applicationDetails.insurer_one.residence.village.cells
                            .sector.district.name,
                      },
                      {
                        label: "Province",
                        value:
                          applicationDetails.insurer_one.residence.village.cells
                            .sector.district.province.name,
                      },
                    ].map((item, index) => (
                      <div key={index} className="flex">
                        <span className="font-semibold w-32 text-gray-700">
                          {item.label}:
                        </span>
                        <span className="text-gray-600">{item.value}</span>
                      </div>
                    ))}
                  </div>

                  {applicationDetails.insurer_two && (
                    <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                      <h3 className="font-semibold text-lg text-gray-800 mb-2">
                        Second Guarantor
                      </h3>
                      {[
                        {
                          label: "First Name",
                          value: applicationDetails.insurer_two.first_name,
                        },
                        {
                          label: "Last Name",
                          value: applicationDetails.insurer_two.last_name,
                        },
                        {
                          label: "Phone",
                          value: applicationDetails.insurer_two.phone,
                        },
                        {
                          label: "National ID",
                          value: applicationDetails.insurer_two.identity,
                        },
                        {
                          label: "Occupation",
                          value: applicationDetails.insurer_two.occupation,
                        },
                        {
                          label: "Village",
                          value:
                            applicationDetails.insurer_two.residence.village
                              .name,
                        },
                        {
                          label: "Cell",
                          value:
                            applicationDetails.insurer_two.residence.village
                              .cells.name,
                        },
                        {
                          label: "Sector",
                          value:
                            applicationDetails.insurer_two.residence.village
                              .cells.sector.name,
                        },
                        {
                          label: "District",
                          value:
                            applicationDetails.insurer_two.residence.village
                              .cells.sector.district.name,
                        },
                        {
                          label: "Province",
                          value:
                            applicationDetails.insurer_two.residence.village
                              .cells.sector.district.province.name,
                        },
                      ].map((item, index) => (
                        <div key={index} className="flex">
                          <span className="font-semibold w-32 text-gray-700">
                            {item.label}:
                          </span>
                          <span className="text-gray-600">{item.value}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-9">
                  <span className="text-lg font-bold text-gray-900 mb-">
                    Uploaded files
                  </span>
                  {applicationDetails.files &&
                  applicationDetails.files.length > 0 ? (
                    <div className="flex flex-wrap gap-4">
                      {applicationDetails.files.map((file, index) => {
                        const fileName = file.split("/").pop();
                        const fileType = fileName.split("-")[3].split(".")[0];
                        return (
                          <div
                            key={index}
                            className="flex flex-col items-center"
                          >
                            <a
                              href={file}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              Open {fileType}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>No files uploaded.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </DashboardLayout>
  );
}

export default JaliApplicants;
