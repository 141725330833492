import React, { useState, useEffect } from "react";
import { message } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const DownloadButton = ({ applicantData }) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const generatePDF = async () => {
    setIsGenerating(true);
    const messageKey = 'generate-pdf';

    try {
      message.loading({ content: 'Generating PDF...', key: messageKey, duration: 0 });

      // Initialize PDF document
      const doc = new jsPDF();
      
      // Add title
      doc.setFontSize(16);
      doc.text('Applicant Details', 105, 15, { align: 'center' });
      
      // Add applicant photo if available
      if (applicantData.files && applicantData.files.length > 0) {
        const photoFile = applicantData.files.find(file => {
          const fileName = file.split("/").pop();
          return fileName.split("-")[3] === "PHOTO";
        });
        
        if (photoFile) {
          try {
            const response = await fetch(photoFile);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            
            reader.onloadend = function() {
              const base64data = reader.result;
              doc.addImage(base64data, 'JPEG', 75, 25, 60, 60);
              continueGeneratingPDF();
            }
          } catch (error) {
            console.error('Error loading photo:', error);
            continueGeneratingPDF();
          }
        } else {
          continueGeneratingPDF();
        }
      } else {
        continueGeneratingPDF();
      }

      function continueGeneratingPDF() {
        // Personal Information
        doc.setFontSize(14);
        doc.text('Personal Information', 14, 100);
        
        const personalInfo = [
          ['First Name', applicantData.first_name],
          ['Last Name', applicantData.last_name],
          ['Date of Birth', new Date(applicantData.dob).toLocaleDateString()],
          ['National ID', applicantData.nid],
          ['Phone Number', applicantData.phone],
          ['Marital Status', applicantData.status]
        ];

        doc.autoTable({
          startY: 105,
          head: [['Field', 'Value']],
          body: personalInfo,
          theme: 'grid',
          styles: { fontSize: 10 },
          margin: { left: 14 }
        });

        // Address Information
        doc.text('Address Information', 14, doc.lastAutoTable.finalY + 10);
        
        const addressInfo = [
          ['Province', applicantData.residence.village.cells.sector.district.province.name],
          ['District', applicantData.residence.village.cells.sector.district.name],
          ['Sector', applicantData.residence.village.cells.sector.name],
          ['Cell', applicantData.residence.village.cells.name],
          ['Village', applicantData.residence.village.name]
        ];

        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 15,
          head: [['Field', 'Value']],
          body: addressInfo,
          theme: 'grid',
          styles: { fontSize: 10 },
          margin: { left: 14 }
        });

        // Motorcycle Information
        doc.text('Motorcycle Information', 14, doc.lastAutoTable.finalY + 10);
        
        const motoInfo = [
          ['Type', applicantData.moto_leasing.type],
          ['Experience', applicantData.moto_experience],
          ['Daily Income', applicantData.daily_income],
          ['Motorcycle Owner', applicantData.moto_ownership],
          ['Driving License', applicantData.driving_licency],
          ['License Issue Date', applicantData.driving_licency_issued_date ? 
            new Date(applicantData.driving_licency_issued_date).toLocaleDateString() : 'N/A']
        ];

        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 15,
          head: [['Field', 'Value']],
          body: motoInfo,
          theme: 'grid',
          styles: { fontSize: 10 },
          margin: { left: 14 }
        });

        // Add new page for guarantor information
        doc.addPage();

        // First Guarantor
        doc.text('First Guarantor Information', 14, 15);
        
        const guarantor1Info = [
          ['First Name', applicantData.insurer_one.first_name],
          ['Last Name', applicantData.insurer_one.last_name],
          ['Phone', applicantData.insurer_one.phone],
          ['National ID', applicantData.insurer_one.identity],
          ['Occupation', applicantData.insurer_one.occupation],
          ['Village', applicantData.insurer_one.residence.village.name],
          ['Cell', applicantData.insurer_one.residence.village.cells.name],
          ['Sector', applicantData.insurer_one.residence.village.cells.sector.name],
          ['District', applicantData.insurer_one.residence.village.cells.sector.district.name],
          ['Province', applicantData.insurer_one.residence.village.cells.sector.district.province.name]
        ];

        doc.autoTable({
          startY: 20,
          head: [['Field', 'Value']],
          body: guarantor1Info,
          theme: 'grid',
          styles: { fontSize: 10 },
          margin: { left: 14 }
        });

        // Second Guarantor (if exists)
        if (applicantData.insurer_two) {
          doc.text('Second Guarantor Information', 14, doc.lastAutoTable.finalY + 10);
          
          const guarantor2Info = [
            ['First Name', applicantData.insurer_two.first_name],
            ['Last Name', applicantData.insurer_two.last_name],
            ['Phone', applicantData.insurer_two.phone],
            ['National ID', applicantData.insurer_two.identity],
            ['Occupation', applicantData.insurer_two.occupation],
            ['Village', applicantData.insurer_two.residence.village.name],
            ['Cell', applicantData.insurer_two.residence.village.cells.name],
            ['Sector', applicantData.insurer_two.residence.village.cells.sector.name],
            ['District', applicantData.insurer_two.residence.village.cells.sector.district.name],
            ['Province', applicantData.insurer_two.residence.village.cells.sector.district.province.name]
          ];

          doc.autoTable({
            startY: doc.lastAutoTable.finalY + 15,
            head: [['Field', 'Value']],
            body: guarantor2Info,
            theme: 'grid',
            styles: { fontSize: 10 },
            margin: { left: 14 }
          });
        }

        // Save the PDF
        doc.save(`${applicantData.first_name}_${applicantData.last_name}_application.pdf`);
        message.success({ content: 'PDF generated successfully!', key: messageKey });
      }

    } catch (error) {
      console.error('PDF generation failed:', error);
      message.error({ content: 'Failed to generate PDF. Please try again.', key: messageKey });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <button
      onClick={generatePDF}
      disabled={isGenerating}
      className="px-3 cursor-pointer rounded-md disabled:opacity-50"
      aria-label="Generate PDF"
    >
      <FontAwesomeIcon
        icon={faDownload}
        size="1x"
        className={`text-[#23419B] ${isGenerating ? 'opacity-50' : ''}`}
      />
    </button>
  );
};

export default DownloadButton;