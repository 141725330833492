import React, { useEffect, useState } from "react";
import "./App.css";
import Signup from "./components/auth/Signup";
import Login from "./components/auth/Login";
import Reset from "./components/auth/Reset";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import Statistics from "./components/dashboard/pages/Statistics";
import CreateRider from "./components/dashboard/pages/CreateRider";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import CreateAsset from "./components/dashboard/pages/CreateAsset";
import AssignAsset from "./components/dashboard/pages/AssignAsset";
import { ToastProvider, useToasts } from "react-toast-notifications";
import RiderPayments from "./components/dashboard/pages/ RiderPayments";
import JaliApplicants from "./components/dashboard/pages/JaliApplicants";
import UserProfile from "./components/dashboard/pages/UserProfile";
import moment from "moment";
import PaymentsReceipts from "./components/dashboard/pages/PaymentsReceipts";
import { useSelector } from "react-redux";
import {
  checkloggedInStatus,
  getAgent,
  getUser,
} from "./redux/transactions/UntappedSlice";
import LandingPage from "./components/morideHome/LandingPage";
import ApplicationForm from "./components/loanProcess/ApplicationForm";
import AgentDashboard from "./components/loanAgent/AgentDashboard";
import JaliAgent from "./components/dashboard/pages/JaliAgent";

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const user_info = useSelector(getUser);
  const accessTokenExpiry = user_info
    ? moment(user_info.access_token_expiry).format("YYYY-MM-DD h:mm:ss a")
    : "";
  const currentTime = moment().format("YYYY-MM-DD h:mm:ss a");
  const isLoggedIn = user_info === null ? false : accessTokenExpiry > currentTime;

  React.useEffect(() => {
    if (!isLoggedIn) {
      addToast('Please log in to access this page', {
        appearance: 'warning',
        autoDismiss: true,
      });
      navigate('/', { 
        state: { from: location.pathname },
        replace: true 
      });
    }
  }, [isLoggedIn, navigate, addToast, location]);

  return isLoggedIn ? children : null;
};

// Protected Agent Route Component
const ProtectedAgentRoute = ({ children }) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const location = useLocation();
  const loggedInAgent = useSelector(getAgent);
  const isLoggedInAgent = loggedInAgent !== null;

  React.useEffect(() => {
    if (!isLoggedInAgent) {
      addToast('Please log in as an agent to access this page', {
        appearance: 'warning',
        autoDismiss: true,
      });
      navigate('/jali-portal', { 
        state: { from: location.pathname },
        replace: true 
      });
    }
  }, [isLoggedInAgent, navigate, addToast, location]);

  return isLoggedInAgent ? children : null;
};

function App() {
  const user_info = useSelector(getUser);
  const loggedInAgent = useSelector(getAgent);
  const accessTokenExpiry = user_info
    ? moment(user_info.access_token_expiry).format("YYYY-MM-DD h:mm:ss a")
    : "";
  const currentTime = moment().format("YYYY-MM-DD h:mm:ss a");
  
  return (
    <ToastProvider>
      <div>
        <Router>
          <div className="justify-center">
            <Routes>
              {/* Public Routes */}
              <Route exact path="/" element={<Login />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/sign-up" element={<Signup />} />
              <Route path="/jali-portal" element={<LandingPage />} />
              <Route path="/jali-application-form" element={<ApplicationForm />} />

              {/* Protected Agent Routes */}
              <Route
                path="/jali-application-agent"
                element={
                  <ProtectedAgentRoute>
                    <AgentDashboard />
                  </ProtectedAgentRoute>
                }
              />

              {/* Protected User Routes */}
              <Route
                path="/dashboard-home/*"
                element={
                  <ProtectedRoute>
                    <DashboardLayout />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/statistics"
                element={
                  <ProtectedRoute>
                    <Statistics />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-asset"
                element={
                  <ProtectedRoute>
                    <CreateAsset />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-rider"
                element={
                  <ProtectedRoute>
                    <CreateRider />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assign-asset"
                element={
                  <ProtectedRoute>
                    <AssignAsset />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/rider-payments"
                element={
                  <ProtectedRoute>
                    <RiderPayments />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/payments-receipts"
                element={
                  <ProtectedRoute>
                    <PaymentsReceipts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jali-agent"
                element={
                  <ProtectedRoute>
                    <JaliAgent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jali-dashboard"
                element={
                  <ProtectedRoute>
                    <JaliApplicants />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </Router>
      </div>
    </ToastProvider>
  );
}

export default App;