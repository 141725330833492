import React, { useEffect, useState } from "react";
import LandingNavBar from "../navBar/LandingNavBar";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message } from "antd";
import {
  faBiking,
  faFile,
  faLocationDot,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsynagentApplications,
  getAgent,
  getAllAgentApplications,
  isLoadingAgentsApplication,
} from "../../redux/transactions/UntappedSlice";
import NoApplicationsSection from "./NodataAvailable";

const RiderPortal = () => {
  const dispatch = useDispatch();
  const allAgentApplications = useSelector(getAllAgentApplications);

  // console.log('allAgentApplications',allAgentApplications)
  const loggedInAgent = useSelector(getAgent);
  const loadingAgentsApplication = useSelector(isLoadingAgentsApplication);
  const [isConfirmLoader, setIsConfirmLoader] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredApplicants, setFilteredApplicants] = useState([]);

  const applicants = allAgentApplications?.applicants || [];

  useEffect(() => {
    
    dispatch(fetchAsynagentApplications({loggedInAgent}));
  }, [dispatch]);

  // Search functionality
  useEffect(() => {
    if (searchQuery) {

      if (!searchQuery.trim()) {
        setFilteredApplicants(applicants);
        return;
      }
      const query = searchQuery.toLowerCase().trim();
      const filtered = applicants.filter((applicant) => {
        setIsConfirmLoader(true);
        // Search across multiple fields
        const searchableFields = [
          applicant.first_name?.toLowerCase(),
          applicant.middle_name?.toLowerCase(),
          applicant.last_name?.toLowerCase(),
          applicant.phone?.toLowerCase(),
          "kigali", // since location is hardcoded in the UI
          applicant.decision?.toLowerCase(),
        ];
        return searchableFields.some((field) => field && field.includes(query));
      });
      setFilteredApplicants(filtered);
      setIsConfirmLoader(false);
    }
  }, [searchQuery, applicants]);
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-gradient-to-br from-gray-900 via-[#20409A] to-blue-900">
        <LandingNavBar />
      </div>

      {/* Hero Section */}
      <section className="bg-gradient-to-r from-[#17295A] via-[#20409A] to-[#17295A] text-white py-16 text-center">
        <div className="container mx-auto px-4">
          {/* Title & Description */}
          <h2 className="text-3xl md:text-4xl font-bold">
            Manage Applications Seamlessly
          </h2>
          <p className="text-base md:text-lg my-2">
            Track, and monitor moto rider applications easily
          </p>

          {/* Search Input & Button */}
          <div className="flex justify-center items-center gap-2 mt-4 w-full">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search by name, phone, location..."
              className="px-4 py-2 rounded shadow-md text-black border-gray-300 focus:outline-none w-full sm:w-full md:w-full lg:w-[30%] "
            />
            <Button
              type="primary"
              loading={isConfirmLoader}
              className="bg-[#20409A] px-4 py-2 rounded shadow-md text-sm text-white hover:bg-[#20409A] flex items-center w-auto"
            >
              Search
            </Button>
          </div>

          {/* Statistics Section */}
          <div className="mt-8 flex flex-wrap justify-center gap-6 md:gap-12">
            <div className="text-center">
              <p className="text-2xl md:text-3xl font-bold">
                {filteredApplicants.length}
              </p>
              <p className="text-sm md:text-base">Applications</p>
            </div>
            <div className="text-center">
              <p className="text-2xl md:text-3xl font-bold">
                {filteredApplicants.filter((a) => !a.decision).length}
              </p>
              <p className="text-sm md:text-base">Pending</p>
            </div>
            <div className="text-center">
              <p className="text-2xl md:text-3xl font-bold">
                {
                  filteredApplicants.filter(
                    (a) => a.decision === "Under Review"
                  ).length
                }
              </p>
              <p className="text-sm md:text-base">Under Review</p>
            </div>
            <div className="text-center">
              <p className="text-2xl md:text-3xl font-bold">
                {
                  filteredApplicants.filter((a) => a.decision === "Approved")
                    .length
                }
              </p>
              <p className="text-sm md:text-base">Approved</p>
            </div>
          </div>
        </div>
      </section>

      {/* Rider Listings Section */}
      {applicants.length > 0 && !loadingAgentsApplication ? (
        <section className="py-16">
          <div className="container mx-auto px-4">
            <div className="flex justify-between items-center mb-8">
              <h3 className="text-2xl font-bold">
                {searchQuery
                  ? "Search Results"
                  : "Recent Applications Available"}
              </h3>
              <a href="#" className="text-[#20409A] underline">
                View all
              </a>
            </div>

            <div className="space-y-6">
              {filteredApplicants.map((Rider) => (
                <div
                  key={Rider.phone}
                  className="bg-white p-6 rounded shadow-md flex justify-between"
                >
                  <div className="">
                    <div className="mb-2">
                      <span className="text-sm text-[#20409A] bg-slate-200 rounded-md px-2 py-1">
                        10 min ago
                      </span>
                    </div>

                    <div className="flex justify-start py-2 items-center">
                      <div className="flex items-center">
                        <Avatar
                          shape="square"
                          size={64}
                          className="flex justify-center items-center"
                        >
                          <div className="flex items-center justify-center h-full w-full">
                            <UserOutlined />
                          </div>
                        </Avatar>
                      </div>
                      <div className="ml-4">
                        <h4 className="text-xl font-md">
                          {Rider.first_name} {Rider.middle_name}{" "}
                          {Rider.last_name}
                        </h4>
                        <div className="flex flex-col">
                          <span className="text-gray-500 text-sm">
                            Phone{" "}
                            <span className="text-[#20409A]">
                              {Rider.phone}
                            </span>
                          </span>
                          <span className="text-gray-500 text-sm">
                            Applied for a bike loan by{" "}
                            <span className="text-[#20409A] mx-2">
                              {loggedInAgent.agent.firstname}{" "}
                              {loggedInAgent.agent.lastname}
                            </span>
                            on 25th Jan 2025
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-4 mt-2">
                      <div className="flex justify-center items-center text-sm text-gray-500">
                        <span className="flex justify-center items-center">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                        <span className="text-sm text-gray-500 mx-1">
                          Kigali
                        </span>
                      </div>
                      <div className="flex justify-center items-center text-sm text-gray-500">
                        <span className="flex justify-center items-center">
                          <FontAwesomeIcon icon={faBiking} />
                        </span>
                        <span className="text-sm text-gray-500 mx-1">bike</span>
                      </div>
                      <div className="flex justify-center items-center text-sm text-gray-500">
                        <span className="flex justify-center items-center">
                          <FontAwesomeIcon icon={faPerson} />
                        </span>
                        <span className="text-sm bg-yellow-300 text-white px-1 rounded mx-1">
                          Guarantor
                        </span>
                      </div>
                      <div className="flex justify-center items-center text-sm text-gray-500">
                        <span className="flex justify-center items-center">
                          <FontAwesomeIcon icon={faFile} />
                        </span>
                        <span className="text-sm bg-[#58a5b3] px-1 rounded text-white mx-1">
                          Documents
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between items-end">
                    <div>
                      <div className="text-sm text-[#20409A] text-center bg-slate-200 rounded-md px-2 py-1">
                        {Rider.decision || "Under Review"}
                      </div>
                    </div>
                    <button className="bg-[#20409A] text-white text-sm px-2 py-2 rounded hover:bg-[#20409A]">
                      Rider Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : loadingAgentsApplication ? (
        <div className="text-center text-lg text-gray-500">Loading...</div>
      ) : (
        <NoApplicationsSection />
      )}
    </div>
  );
};

export default RiderPortal;
