import React, { useState, useEffect } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ImageUploadProviderImage from "./ImageUploadProviderImage";

const UploadedCopy = ({ data, onChange }) => {
  const [formData, setFormData] = useState({
    profileImage: null,
    nationalIdCopy: null,
    drivingLicenseCopy: null,
    residenceProof: null,
    loanApplicationLetter: null,
    maritalStatusProof: null,
    paymentReceipt: null,
  });

  // Sync state with incoming data from props
  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(formData)) {
      setFormData(data);
    }
  }, [data]);

  // Debounced sync with parent component
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (JSON.stringify(formData) !== JSON.stringify(data)) {
        onChange(formData);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [formData, data, onChange]);

  const handleImageSelect = (imageFile) => {
    
    localStorage.setItem("imageFile", JSON.stringify(imageFile));
    setFormData((prevData) => ({ ...prevData, profileImage: imageFile }));
  };

  const validateFile = (file) => {
    const isPDF = file.type === "application/pdf";
    const isSmallEnough = file.size / 1024 / 1024 < 1;

    if (!isPDF) message.error(`${file.name} is not a PDF file!`);
    if (!isSmallEnough)
      message.error(`${file.name} exceeds the 1MB size limit!`);

    return isPDF && isSmallEnough;
  };

  const handleFileChange = ({ file }, fieldName) => {
    console.log("file", file);
    if (file.status === "removed") {
      setFormData((prevData) => ({ ...prevData, [fieldName]: null }));
    } else if (validateFile(file)) {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: file || file,
      }));
      message.success(`${file.name} has been added successfully.`);
    }
  };

  const uploadProps = (fieldName) => ({
    customRequest: ({ onSuccess }) => onSuccess("ok"),
    beforeUpload: (file) => validateFile(file) || Upload.LIST_IGNORE,
    onChange: (info) => handleFileChange(info, fieldName),
    fileList: formData[fieldName] ? [formData[fieldName]] : [],
  });

  return (
    <form>
      {/* Profile Image Upload with Cropping */}
      <div>
        <label className="block text-sm font-medium mb-2">
          Injiza ifoto yawe
        </label>
        <ImageUploadProviderImage uploadedImage={formData.profileImage} onImageSelect={handleImageSelect} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {[
          { name: "nationalIdCopy", label: "Injiza copy y'irangamuntu" },
          { name: "drivingLicenseCopy", label: "Injiza copy ya perimi" },
          { name: "residenceProof", label: "Injiza icyangombwa cy'aho utuye" },
          { name: "loanApplicationLetter", label: "Injiza ibaruwa isaba inguzanyo" },
          { name: "maritalStatusProof", label: "Icyemezo cy'uko uri ingaragu / washatse" },
          { name: "paymentReceipt", label: "Injiza icyemeza ko wishyuye (Receipt)" },
        ].map(({ name, label }) => (
          <div key={name}>
            <label className="block text-sm font-medium mb-2">{label}</label>
            <Upload {...uploadProps(name)}>
              <Button className="text-white" icon={<UploadOutlined />}>
                Choose File
              </Button>
            </Upload>
          </div>
        ))}
      </div>
    </form>
  );
};

export default UploadedCopy;
