import React from "react";

const MotorcycleLoanShowcase = () => {
  return (
    <div className="w-full max-w-6xl mx-auto px-4 py-12">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row items-center gap-12">
        {/* Left Content */}
        <div className="w-full md:w-1/2 space-y-6">
          <div className="flex">
            <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center mr-3">
              <svg
                className="w-5 h-5 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h1 className="text-white text-3xl font-bold mb-4">
              Everything you need
              <br />
              in one place
            </h1>
          </div>
          <h2 className="text-4xl md:text-xl font-bold text-white">
            Get Your Dream Bike
            <span className="text-blue-400"> Faster</span>
          </h2>

          <p className="text-gray-400 text-lg">
            Quick approvals, competitive rates, and hassle-free process to get
            you on the road.
          </p>

          <div className="flex gap-4">
            <a
              href="/jali-application-form"
              className="text-gray-400 hover:text-white"
            >
              <button className="px-6 py-3 bg-blue-600 hover:bg-blue-600 text-white rounded-lg font-medium transition-all transform hover:scale-105">
                Apply Now
              </button>
            </a>

            <a
              href="/jali-application-agent"
              className="text-gray-400 hover:text-white ml-8"
            >
              <button className="px-6 py-3 bg-gray-800 hover:bg-gray-700 text-white rounded-lg font-medium transition-all">
                Agent
              </button>
            </a>
          </div>
        </div>

        {/* Right Side Cards */}
        <div className="w-full md:w-1/2 relative animate-fade-in">
          <div className="grid grid-cols-2 gap-4  inset-0 animate-float ">
            {/* Feature Cards */}
            <div className="col-span-2">
              <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 p-6 rounded-2xl border border-red-500/20 transform hover:scale-105 transition-all">
                <div className="text-2xl font-bold text-white mb-2">
                  10,000 RWF - 150,000 RWF
                </div>
                <div className="text-gray-400">Maximum other Loan Amount</div>
              </div>
            </div>

            <div className="bg-gradient-to-br from-blue-500/10 to-purple-500/10 p-6 rounded-2xl border border-blue-500/20 transform hover:scale-105 transition-all">
              <div className="text-2xl font-bold text-white mb-2">8.99%</div>
              <div className="text-gray-400">Interest Rate</div>
            </div>

            <div className="bg-gradient-to-br from-purple-500/10 to-pink-500/10 p-6 rounded-2xl border border-purple-500/20 transform hover:scale-105 transition-all">
              <div className="text-2xl font-bold text-white mb-2">24h</div>
              <div className="text-gray-400">Fast Approval</div>
            </div>

            {/* Benefits List */}
            <div className="col-span-2 bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-6 rounded-2xl border border-gray-700">
              <h3 className="text-white font-semibold mb-4">Quick Benefits</h3>
              <ul className="space-y-3">
                <li className="flex items-center gap-3 text-gray-300">
                  <svg
                    className="w-5 h-5 text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  Minimal Documentation
                </li>
                <li className="flex items-center gap-3 text-gray-300">
                  <svg
                    className="w-5 h-5 text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  Flexible Repayment Options
                </li>
                <li className="flex items-center gap-3 text-gray-300">
                  <svg
                    className="w-5 h-5 text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  No Hidden Charges
                </li>
              </ul>
            </div>
          </div>

          <style jsx>{`
            @keyframes float {
              0%,
              100% {
                transform: translateY(0px);
              }
              50% {
                transform: translateY(-20px);
              }
            }

            @keyframes fade-in {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            @keyframes slide-right {
              from {
                transform: translateX(-50px);
                opacity: 0;
              }
              to {
                transform: translateX(0);
                opacity: 1;
              }
            }

            .animate-float {
              animation: float 6s ease-in-out infinite;
            }

            .animate-fade-in {
              animation: fade-in 1s ease-out forwards;
            }

            .animate-slide-right {
              animation: slide-right 0.5s ease-out forwards;
            }

            .delay-100 {
              animation-delay: 0.1s;
            }

            .delay-200 {
              animation-delay: 0.2s;
            }

            .perspective-1000 {
              perspective: 1000px;
            }
          `}</style>

          {/* Floating Elements */}
          <div className="absolute -top-4 -right-4 w-20 h-20 bg-blue-500/10 rounded-full blur-xl"></div>
          <div className="absolute -bottom-4 -left-4 w-20 h-20 bg-blue-500/10 rounded-full blur-xl"></div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-8 rounded-2xl border border-gray-700/50 text-center">
          <div className="text-2xl font-bold text-white mb-2">90%</div>
          <div className="text-gray-400">Customer Satisfaction</div>
        </div>
        <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-8 rounded-2xl border border-gray-700/50 text-center">
          <div className="text-2xl font-bold text-white mb-2">10k+</div>
          <div className="text-gray-400">Loans Disbursed</div>
        </div>
        <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-8 rounded-2xl border border-gray-700/50 text-center">
          <div className="text-2xl font-bold text-white mb-2">5.0</div>
          <div className="text-gray-400">User Rating</div>
        </div>
      </div>
    </div>
  );
};

export default MotorcycleLoanShowcase;
