import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncMotoleasing,
  getAllMotoleasingList,
  getUser,
} from "../../redux/transactions/UntappedSlice";

function YourIncome({ data,transmission, HandleIncomeData }) {
  const dispatch = useDispatch();
  const user_info = useSelector(getUser);
  const allMotoleasingList = useSelector(getAllMotoleasingList);

  const [formData, setFormData] = useState({
    motariDuration: { type: "", value: "" },
    dailyIncome: "",
    motoOwnership: "",
    hasDebt: "",
    preferredMotoType: "",
    paymentMode: "Buri Cyumweru", 
  });

  useEffect(() => {
    dispatch(fetchAsyncMotoleasing());
  }, [dispatch]);
  // Update state only if incoming `data` changes
  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(formData)) {
      setFormData(data);
    }
  }, [data]);

  // Notify parent only if `formData` changes
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (JSON.stringify(formData) !== JSON.stringify(data)) {
        HandleIncomeData(formData);
      }
    }, 500); // Debounce updates

    return () => clearTimeout(timeout);
  }, [formData, data, HandleIncomeData]);
  
  // Convert "true" to true, "false" to false
  const handlehouseOwnershipChange = (event) => {
    const { name, value } = event.target;
    
    let parsedValue;
    if (name === "hasDebt") {
      parsedValue = value === "true"; 
    } else {
      parsedValue = value;
    }
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
    }));
  };

  const handleChangeMoto = (e) => {
    const selectedMoto = JSON.parse(e.target.value); // Convert string back to object
    setFormData((prev) => ({
      ...prev,
      preferredMotoType: selectedMoto,
    }));
  };
  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle motariDuration change separately
  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      motariDuration: { ...prevData.motariDuration, [name]: value },
    }));
  };

  return (
    <form>
      {/* Motari Duration */}
      <div className="flex justify-between items-center">
        <div className="mb-4 w-full">
          <label className="block text-sm font-medium mb-2">
            Umaze igihe kingana iki mu Kimotari?
          </label>
          <div className="flex w-full">
            <select
              name="type"
              value={formData.motariDuration && formData.motariDuration.type}
              onChange={handleDurationChange}
              className="w-2/4 mr-1 px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            >
              <option value="" disabled>
                Hitamo Igihe
              </option>
              <option value="Imyaka">Imyaka</option>
              <option value="Amezi">Amezi</option>
              <option value="Iminsi">Iminsi</option>
            </select>
            <input
              type="number"
              name="value"
              value={formData.motariDuration && formData.motariDuration.value}
              onChange={handleDurationChange}
              placeholder="Uzuza hano umubare w'igihe"
              className="w-full px-3 focus:outline-none py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
            />
          </div>
        </div>
      </div>

      {/* Daily Income and Moto Ownership */}
      <div className="flex justify-between items-center">
        <div className="mb-4 w-1/2 mr-4">
          <label className="block text-sm font-medium mb-2">
            Amafaranga winjiza ku munsi?
          </label>
          <input
            type="number"
            name="dailyIncome"
            value={formData.dailyIncome}
            onChange={handleChange}
            className="w-full px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div className="mb-4 w-1/2">
          <label className="block text-sm font-medium mb-2">
            Moto yari iyande?
          </label>
          <select
            name="motoOwnership"
            value={formData.motoOwnership}
            onChange={handleChange}
            className="w-full px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          >
            <option value="" disabled>
              Hitamo
            </option>
            <option value="IYANGE">IYANGE</option>
            <option value="NARAPATANYE">NARAPATANYE</option>
            <option value="VERISOMA">VERISOMA</option>
          </select>
        </div>
      </div>

      {/* Debt and Preferred Moto */}
      <div className="flex justify-between items-center">
        <div className="mb-4 w-1/2 mr-4">
          <label className="block text-sm font-medium mb-2">
            Hari imyenda mufite?
          </label>
          <select
            name="hasDebt"
            value={formData.hasDebt}
            onChange={handlehouseOwnershipChange}
            className="w-full px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          >
            <option value="" disabled>
              Hitamo
            </option>
            <option value={true}>Yego</option>
            <option value={false}>Oya</option>
          </select>
        </div>
        <div className="mb-4 w-1/2">
          <label className="block text-sm font-medium mb-2">
            Hitamo ubwoko bwa <strong>Moto</strong> ushaka
          </label>
          <select
            name="preferredMotoType"
            value={formData.preferredMotoType ? JSON.stringify(formData.preferredMotoType) : ""}
            onChange={handleChangeMoto}
            className="w-full px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          >
            <option value="">Hitamo</option>
            {allMotoleasingList?.map((moto) => (
              <option key={moto.id} value={JSON.stringify(moto)}>
                {moto.type}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Payment Mode */}
      <div className="flex justify-between items-center">
        <div className="mb-4 w-1/2 mr-4">
          <label className="block text-sm font-medium mb-2">
            Uzishyura mubuhe buryo
          </label>
          <input
            type="text"
            disabled
            value={formData.paymentMode}
            className="w-full px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
      </div>
    </form>
  );
}

export default YourIncome;
