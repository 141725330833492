import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncCells,
  fetchAsyncDistricts,
  fetchAsyncSectors,
  fetchAsyncVillages,
  getAllCells,
  getAllDistricts,
  getAllProvinces,
  getAllSectors,
  getAllVillages,
  getUser,
} from "../../redux/transactions/UntappedSlice";
import Location from "./Location";

function RiderIdentification({ data = {}, onChangeHandleRinderInfo }) {
  const dispatch = useDispatch();
  const allProvinces = useSelector(getAllProvinces);
  const allDistricts = useSelector(getAllDistricts);
  const allSectors = useSelector(getAllSectors);
  const allCells = useSelector(getAllCells);
  const allVillages = useSelector(getAllVillages);
  console.log("allVillages", allVillages);

  // Ensure default values to prevent uncontrolled inputs
  const defaultFormData = {
    phone: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    idNumber: "",
    province: "",
    district: "",
    sector: "",
    cell: "",
    village: "",
    gender: "",
  };

  // Initialize state with default values
  const [formData, setFormData] = useState({ ...defaultFormData, ...data });

  // Update formData with the new location data
  const updatedLocation = (location) => {
    setFormData((prevData) => ({
      ...prevData,
      ...location,
    }));
  };

  // Sync formData with `data`, ensuring all fields remain controlled
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setFormData((prev) => ({
        ...prev,
        phone: data.phone ?? "",
        firstName: data.firstName ?? "",
        middleName: data.middleName ?? "",
        lastName: data.lastName ?? "",
        birthDate: data.birthDate ?? "",
        idNumber: data.idNumber ?? "",
        province: data.province ?? "",
        district: data.district ?? "",
        sector: data.sector ?? "",
        cell: data.cell ?? "",
        village: data.village ?? "",
        gender: data.gender ?? "",
      }));
    }
  }, [data]);

  // Handle form updates with debounce
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (JSON.stringify(formData) !== JSON.stringify(data)) {
        onChangeHandleRinderInfo(formData);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [formData, data, onChangeHandleRinderInfo]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch districts when province changes
  useEffect(() => {
    if (formData.province) {
      dispatch(fetchAsyncDistricts({ province: formData.province }));
    }
    if (formData.district) {
      dispatch(fetchAsyncSectors({ district: formData.district }));
    }
    if (formData.sector) {
      dispatch(fetchAsyncCells({ sector: formData.sector }));
    }
    if (formData.cell) {
      dispatch(fetchAsyncVillages({ cell: formData.cell }));
    }
  }, [
    dispatch,
    formData.province,
    formData.district,
    formData.sector,
    formData.cell,
  ]);

  return (
    <form>
      <div className="flex justify-center items-center">
        <div className=" w-1/2 ">
          <label className="block text-sm font-medium ">
            Nimero y'irangamuntu
          </label>
          <input
            type="number"
            name="idNumber"
            value={formData.idNumber}
            onChange={handleChange}
            placeholder="nimero y'irangamuntu"
            className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div className=" w-1/2 ml-4">
          <label className="block text-sm font-medium ">
            Nimero ya telefone
          </label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="078..."
            className="w-full focus:outline-none px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
      </div>
      <div className=" grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium ">Izina ribanza</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Izina ribanza"
            className="w-full px-3 focus:outline-none py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div>
          <label className="block text-sm font-medium ">Izina ryo hagati</label>
          <input
            type="text"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
            placeholder="Izina ryo hagati"
            className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div>
          <label className="block text-sm font-medium ">Irindi zina</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Irindi zina"
            className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
      </div>

      <div className="flex w-full justify-between items-center">
        <div className=" w-2/5 mr-4">
          <label className="block text-sm font-medium ">
            Italiki y'amavuko
          </label>
          <input
            type="date"
            name="birthDate"
            value={formData.birthDate}
            onChange={handleChange}
            className="w-full focus:outline-none px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div className=" w-3/5">
          <label className="block text-sm font-medium ">Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          >
            <option value="" disabled>
              Hitamo
            </option>
            <option value="male">Gabo</option>
            <option value="female">Gore</option>
            <option value="other">Ikindi</option>
          </select>
        </div>
      </div>
      <label className="block text-sm font-medium">
        Aho wavukiye / Aho irangamuntu yatangiwe
      </label>
      <Location
        province={formData.province}
        district={formData.district}
        sector={formData.sector}
        cell={formData.cell}
        village={formData.village}
        onLocationChange={updatedLocation}
      />
    </form>
  );
}

export default RiderIdentification;
