import React from "react";
import "./filter.css";
import moment from "moment";

function Filter() {
  return (
    <div className="flex justify-between items-center w-full px-6 py-4  rounded-lg shadow-lg">
      {/* Left Section */}
      <div className="flex flex-col">
        <h1 className="text-2xl font-extrabold text-white">
          Jali finance portal
        </h1>
        <p className="mt-2 text-md text-red-500 font-medium ">
          Success is our priority! Stay on top of finances! 😊
        </p>
      </div>

      {/* Right Section */}
      <div className="text-right text-white">
        <p className="text-md font-medium">{moment().format("LLLL")}</p>
      </div>
    </div>
  );
}
export default Filter;
