import React, { useState, useEffect } from "react";
import UploadedCopy from "./UploadedCopy";
import ConfirmSection from "./ConfirmSection";
import Guarantor from "./Guarantor";
import WifeIndentification from "./WifeIndentification";
import RiderIdenfification from "./RiderIdenfification";
import BasicInformation from "./BasicInformation";
import YourIncome from "./ YourIncome";
import { Button, message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncMotoleasing,
  fetchAsyncProvinces,
  getAllMotoleasingList,
  getAgent,
} from "../../redux/transactions/UntappedSlice";
import { motion } from "framer-motion";
import SuccessMessage from "./SuccessMessage";

const MultiStepForm = () => {
  const dispatch = useDispatch();
  const loggedInAgent = useSelector(getAgent);
  const stepVariants = {
    initial: { opacity: 0, x: 50 }, // Start faded out and slightly to the right
    animate: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.3, ease: "easeOut" },
    }, // Fade in and slide in
    exit: { opacity: 0, x: -50, transition: { duration: 0.3, ease: "easeIn" } }, // Fade out and slide left
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [darkMode, setDarkMode] = useState(true);
  const [isConfirmedSent, setIsConfirmedSent] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirmLoader, setIsConfirmLoader] = useState(false);
  // Update functions for each section

  const resstApplicationForm= () => {
    setIsConfirmLoader(false);
    setCurrentStep(1)
  };
  const handleBasicInformationChange = (formData) => {
    setRiderBasicInformation(formData);
  };

  const handleRiderIdentificationChange = (formData) => {
    setRiderIdentification(formData);
  };
  const HandleIncomeData = (formData) => {
    setIncomeData(formData);
  };
  const handleGuarantorChange = (formData) => {
    console.log("handleGuarantorChange", formData);
    setGuarantor(formData);
  };
  const handleUploadedCopiesChange = (formData) => {
    setUploadedCopies(formData);
  };

  const handleWifeIdentificationChange = (formData) => {
    setWifeIdentifications(formData);
  };

  const handleConfirmChange = async (checked) => {
    setIsConfirmed(checked);
  };

  // Function to create FormData with files
  const createFormData = async (response) => {
    const applicantId = response?.applicantResponse?.id;

    const reconstructFile = (fileObj) => {
      if (!fileObj || !fileObj.name || !fileObj.type) return null; // Ensure valid file

      return new File([fileObj], fileObj.name, { type: fileObj.type });
    };

    // Process uploadedCopies to reconstruct actual files
    const reconstructedCopies = {
      profileImage: reconstructFile(uploadedCopies.profileImage),
      nationalIdCopy: reconstructFile(uploadedCopies.nationalIdCopy),
      drivingLicenseCopy: reconstructFile(uploadedCopies.drivingLicenseCopy),
      residenceProof: reconstructFile(uploadedCopies.residenceProof),
      loanApplicationLetter: reconstructFile(
        uploadedCopies.loanApplicationLetter
      ),
      maritalStatusProof: reconstructFile(uploadedCopies.maritalStatusProof),
      paymentReceipt: reconstructFile(uploadedCopies.paymentReceipt),
    };

    // Function to convert Base64 to a File object
    const base64ToFile = (base64String, fileName) => {
      if (!base64String) return null;

      // Extract actual Base64 content (remove metadata part)
      const base64Data = base64String.split(",")[1];
      if (!base64Data) return null;

      // Convert Base64 to a binary buffer
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Return a File object
      return new File([blob], fileName, { type: "application/pdf" });
    };

    // Retrieve Base64-encoded files from localStorage and convert them
    const yellowCardFile = base64ToFile(
      localStorage.getItem("MOTARD"),
      "yellow_card.pdf"
    );
    const contractFile = base64ToFile(
      localStorage.getItem("UMUKOZI"),
      "contract.pdf"
    );
    const businessCertFile = base64ToFile(
      localStorage.getItem("UMUCURUZI"),
      "business_certificate.pdf"
    );

    const formData = new FormData();

    // Map of attribute names to their file data
    const fileAttributes = {
      file_PHOTO: reconstructedCopies.profileImage,
      file_ID: reconstructedCopies.nationalIdCopy,
      file_LETTER: reconstructedCopies.loanApplicationLetter,
      file_DRIVING_LICENCY: reconstructedCopies.drivingLicenseCopy,
      file_RECEIPT: reconstructedCopies.paymentReceipt,
      file_MARITAL_STATUS: reconstructedCopies.maritalStatusProof,
      file_VILLAGE: reconstructedCopies.residenceProof,

      // guarantar document
      file_YELLOW_CARD: yellowCardFile,
      file_CONTRACT: contractFile,
      file_BUSINESS_CERTIFICATE: businessCertFile,
    };

    // Append files to FormData if they exist
    Object.entries(fileAttributes).forEach(([key, file]) => {
      if (file) {
        formData.append(key, file);
      }
    });
    // Send to server
    await axios.post(
      `https://applicationbackend.jalikoi.rw/api/v1/upload/${applicantId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };

  const SubmitApplication = async () => {
    // console.log("Submitting Application:", {
    //   riderBasicInformation,
    //   wifeIdentifications,
    //   guarantor,
    //   uploadedCopies,
    //   riderIdentification,
    //   incomeData,
    //   isConfirmed,
    // });
    setIsConfirmLoader(true);
    const { insurer_one } = guarantor;
    const { insurer_two } = guarantor;

    // Destructure both insurer objects
    const {
      nationalId: nationalIdOne,
      firstName: firstNameOne,
      lastName: lastNameOne,
      occupation: occupationOne,
      phoneNumber: phoneNumberOne,
      village: villageOne,
    } = insurer_one;
    const {
      nationalId: nationalIdTwo,
      firstName: firstNameTwo,
      lastName: lastNameTwo,
      occupation: occupationTwo,
      phoneNumber: phoneNumberTwo,
      village: villageTwo,
    } = insurer_two;

    // Create insurerOne and insurerTwo objects
    const insurerOne = {
      identity: nationalIdOne,
      first_name: firstNameOne,
      last_name: lastNameOne,
      gender: "MALE",
      occupation: occupationOne,
      phone: phoneNumberOne,
      residence: {
        village_id: villageOne ? parseInt(villageOne, 10) : null,
      },
    };

    const insurerTwo = {
      identity: nationalIdTwo,
      first_name: firstNameTwo,
      last_name: lastNameTwo,
      gender: "FEMALE",
      occupation: occupationTwo,
      phone: phoneNumberTwo,
      residence: {
        village_id: villageTwo ? parseInt(villageTwo, 10) : null,
      },
    };

    const {
      firstName = "",
      middleName = "",
      lastName = "",
      phoneNumber = "",
      idNumber = "",
      occupation = "",
    } = wifeIdentifications || {};

    const partner = {
      first_name: firstName,
      middlename: middleName,
      last_name: lastName,
      phone: phoneNumber,
      nid: idNumber,
      occupation,
    };

    const requestData = {
      firstname: riderIdentification.firstName ?? "",
      lastname: riderIdentification.lastName ?? "",
      dob: riderIdentification.birthDate ?? "",
      phone: riderIdentification.phone ?? "",
      nid: riderIdentification.idNumber ?? "",
      gender: riderIdentification.gender ?? "",
      residence: {
        village_id: riderIdentification.village
          ? parseInt(riderIdentification.village, 10)
          : "",
      },
      place_of_birth: riderBasicInformation.village
        ? parseInt(riderBasicInformation.village, 10)
        : "",

      driving_licency: riderBasicInformation.licenseNumber ?? "",
      driving_licency_issued_date: riderBasicInformation.issuedDate ?? "",
      status: riderBasicInformation.maritalStatus ?? "",
      cooperative: "",
      home_ownership: riderBasicInformation.houseOwnership ?? "",
      monthly_rent: riderBasicInformation.rent_amount ?? "",
      rent_duration: `${riderBasicInformation.duration?.value ?? ""} ${
        riderBasicInformation.duration?.type ?? ""
      }`,
      moto_experience: `${incomeData.motariDuration?.value ?? ""} ${
        incomeData.motariDuration?.type ?? ""
      }`,
      daily_income: incomeData.dailyIncome ?? "",
      moto_ownership: incomeData.motoOwnership ?? "",
      debts: incomeData.hasDebt ?? "",
      loan_payment_process: incomeData.paymentMode ?? "Buri Cyumweru",
      moto_id: incomeData?.preferredMotoType?.id ?? "",
      partner: partner,
      insurer_one: insurerOne,
      insurer_status: guarantor.insurer_one?.guarantorPosition ?? "",
      agent_id: loggedInAgent?.agent?.id ?? null,
      insurer_two: insurerTwo,
    };

    try {
      const response = await axios.post(
        "https://applicationbackend.jalikoi.rw/api/v1/application/register/",
        requestData
      );
      setIsConfirmLoader(false);
      if (response) {
        createFormData(response.data);
        setIsConfirmedSent(true);
      }

      message.info("Rider registered successfully!");
    } catch (error) {
      setIsConfirmLoader(false);
      console.error("Error registering rider:", error.response.data.message);
      message.error(error.response.data.message);
    }
  };

  const nextStep = () => {
    if (
      currentStep === 4 &&
      riderBasicInformation.maritalStatus !== "NARASHATSE"
    ) {
      setCurrentStep(5); // Skip to the next step (step 5) if step 4 is skipped
    } else {
      setCurrentStep((prev) => Math.min(prev + 1, 7));
    }
  };

  const previousStep = () => {
    if (
      currentStep === 5 &&
      riderBasicInformation.maritalStatus !== "NARASHATSE"
    ) {
      setCurrentStep(3); // Skip step 4 and go back to step 3
    } else {
      setCurrentStep((prev) => Math.max(prev - 1, 1));
    }
  };

  // Store all form data at the parent level
  const [riderBasicInformation, setRiderBasicInformation] = useState(() => {
    return JSON.parse(localStorage.getItem("riderBasicInformation")) || {};
  });
  const [riderIdentification, setRiderIdentification] = useState(() => {
    return JSON.parse(localStorage.getItem("riderIdentification")) || {};
  });
  const [incomeData, setIncomeData] = useState(() => {
    return JSON.parse(localStorage.getItem("incomeData")) || {};
  });
  const [wifeIdentifications, setWifeIdentifications] = useState(() => {
    return JSON.parse(localStorage.getItem("wifeIdentifications")) || {};
  });
  const [guarantor, setGuarantor] = useState(() => {
    return JSON.parse(localStorage.getItem("guarantor")) || {};
  });
  const [uploadedCopies, setUploadedCopies] = useState(() => {
    return JSON.parse(localStorage.getItem("uploadedCopies")) || {};
  });

  // Save data to localStorage when it changes
  useEffect(() => {
    localStorage.setItem(
      "riderBasicInformation",
      JSON.stringify(riderBasicInformation)
    );
    localStorage.setItem(
      "riderIdentification",
      JSON.stringify(riderIdentification)
    );
    localStorage.setItem("incomeData", JSON.stringify(incomeData));
    localStorage.setItem(
      "wifeIdentifications",
      JSON.stringify(wifeIdentifications)
    );
    localStorage.setItem("guarantor", JSON.stringify(guarantor));

    localStorage.setItem("uploadedCopies", JSON.stringify(uploadedCopies));
  }, [
    riderBasicInformation,
    riderIdentification,
    incomeData,
    wifeIdentifications,
    guarantor,
    uploadedCopies,
  ]);

  // Automatically skip WifeIndentification if maritalStatus is not "NARASHATSE"
  useEffect(() => {
    if (
      currentStep === 4 &&
      riderBasicInformation.maritalStatus !== "NARASHATSE"
    ) {
      setCurrentStep(5); // Skip to the next step if maritalStatus is not "NARASHATSE"
    }
  }, [currentStep, riderBasicInformation.maritalStatus]);

  useEffect(() => {
    dispatch(fetchAsyncProvinces());
  }, [dispatch]);
  return (
    <div
      className={`min-h-screen flex flex-col items-center  transition-colors duration-300 w-full ${
        darkMode
          ? " bg-gradient-to-br from-[#151f3b] via-[#20409A] to-[#192853]   text-white"
          : "bg-[#20409A] text-white"
      }`}
    >
      <h2
        className={`text-lg sm:text-xl md:text-2xl lg:text-2xl font-bold py-4 text-center ${
          darkMode ? "text-white" : "bg-[#20409A] text-white"
        }`}
      >
        <span className="inline-block">
          UZUZA UMWIRONDORO W'UMUMOTARI USHAKA GUKORANA NA
        </span>{" "}
        <br></br>
        <span className="inline-block">JALI FINANCE Ltd</span>
      </h2>
      {!isConfirmedSent && (
        <div className="w-full max-w-xl p-6 bg-gradient-to-br from-gray-900/50 to-gray-900/50   rounded-2xl shadow-lg">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-lg font-semibold uppercase">
              {currentStep === 1
                ? "UMWIRONDORO W’UMUMOTARI"
                : currentStep === 2
                ? "AMAKURU Y’IBANZE"
                : currentStep === 3
                ? "amkuru ajyanye nuko winjiza"
                : currentStep === 4
                ? "Umwirondoro w'uwo mwashakanye"
                : currentStep === 5
                ? "Imyirondoro y'umwishingizi"
                : currentStep === 6
                ? "Injiza copy z' ibyangobwa byasabwe hejuru"
                : "KWEMEZA AMAKURU YOSE Y'UMUMOTARI"}
            </h1>
          </div>

          {currentStep === 1 && (
            <motion.div
              key="step1"
              variants={stepVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <RiderIdenfification
                data={riderIdentification}
                onChangeHandleRinderInfo={handleRiderIdentificationChange}
              />
            </motion.div>
          )}

          {currentStep === 2 && (
            <motion.div
              key="step2"
              variants={stepVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <BasicInformation
                data={riderBasicInformation}
                basicInformation={handleBasicInformationChange}
              />
            </motion.div>
          )}

          {currentStep === 3 && (
            <motion.div
              key="step3"
              variants={stepVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <YourIncome
                data={incomeData}
                transmission={incomeData && incomeData.preferredMotoType?.id}
                HandleIncomeData={HandleIncomeData}
              />
            </motion.div>
          )}

          {currentStep === 4 &&
            riderBasicInformation.maritalStatus === "NARASHATSE" && (
              <motion.div
                key="step4"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <WifeIndentification
                  data={wifeIdentifications}
                  onChangeWifeIdentifications={handleWifeIdentificationChange}
                />
              </motion.div>
            )}

          {currentStep === 5 && (
            <motion.div
              key="step5"
              variants={stepVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <Guarantor
                data={guarantor}
                bikeType={
                  incomeData && incomeData.preferredMotoType?.transmission
                }
                onChange={handleGuarantorChange}
              />
            </motion.div>
          )}

          {currentStep === 6 && (
            <motion.div
              key="step6"
              variants={stepVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <UploadedCopy
                data={uploadedCopies}
                onChange={handleUploadedCopiesChange}
              />
            </motion.div>
          )}

          {currentStep === 7 && (
            <motion.div
              key="step7"
              variants={stepVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <ConfirmSection
                isConfirmed={isConfirmed}
                onConfirmChange={handleConfirmChange}
              />
            </motion.div>
          )}

          <div className="flex justify-between mt-6 ">
            <div>
              {currentStep > 1 && (
                <Button
                  type="primary"
                  onClick={previousStep}
                  className="px-4 py-2 bg-gray-200 text-black rounded-lg flex items-center"
                  disabled={currentStep === 1}
                >
                  Ahabanza
                </Button>
              )}
            </div>
            <div className="flex space-x-4">
              {currentStep < 7 && (
                <Button
                  type="primary"
                  onClick={nextStep}
                  className="px-4 py-2 bg-blue-900 text-white rounded-lg flex items-center"
                >
                  <div className="">Komeza</div>
                </Button>
              )}

              {currentStep === 7 && (
                <Button
                  type="primary"
                  disabled={!isConfirmed}
                  onClick={SubmitApplication}
                  loading={isConfirmLoader}
                  className="px-4 py-2 bg-blue-900 text-white rounded-lg flex items-center"
                >
                  Iyandikishe
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {isConfirmedSent && (
        <div className="w-full max-w-xl p-6 bg-gradient-to-br from-gray-900/50 to-gray-900/50   rounded-2xl shadow-lg">
          <SuccessMessage />
          <Button onClick={resstApplicationForm} >Reset form</Button>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
