import React, { useState, useEffect } from "react";
import DashboardLayout from "../DashboardLayout";
import { Pagination, Spin, message, Button, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPenToSquare,
  faBookReader,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchAsyncApplicantsApplications,
  loadingAlldata,
  getAllApplicantsApplications,
  getAllAgent,
  fetchAsynAgents,
} from "../../../redux/transactions/UntappedSlice";
import EditDrawer from "./EditApplicationDrawer";
import axios from "axios";

function JaliAgent() {
  const alleAgentList = useSelector(getAllAgent);

  console.log("alleAgentList", alleAgentList);
  const allpaginatedApplication = useSelector(getAllApplicantsApplications);
  const isLoading = useSelector(loadingAlldata);
  const dispatch = useDispatch();

  const [searchQuery, setsearchQuery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [statuses, setStatuses] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [applicationDetails, setViewApplicationDetails] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [hasAttemptedDownload, setHasAttemptedDownload] = useState(false);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setsearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    console.log("page", page);
    setCurrentPage(page);
  };

  const handleDownloadPdf = async (applicantId) => {
    setIsDownloading(true);
    setDownloadError(null);
    setHasAttemptedDownload(true);
    try {
      const response = await fetch(
        `https://applicationbackend.jalikoi.rw/api/v1/pdf/applicants/${applicantId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `applicant_${applicantId}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setIsDownloading(false);
    } catch (error) {
      console.error("Failed to download PDF", error);
      setIsDownloading(false);
      setDownloadError(error);
    }
  };

  const formatYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${year}-${month}-${day}`;
  };

  const [triggerSubmission, setTriggerSubmission] = useState(false);

  const saveChanges = () => {
    setTriggerSubmission(true);
    console.log(triggerSubmission);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleViewRider = (applicant) => {
    setViewApplicationDetails(applicant);
    setDrawerVisible(true);
  };

  const handleEditRider = (applicant) => {
    setIsEditMode(true);
    setViewApplicationDetails(applicant);
    // setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setViewApplicationDetails(null);
    setIsEditMode(false);
  };
  const handleApprove = async (id) => {
    try {
      // Prepare the data to send
      const decision = {
        decision: "APPROVED",
      };

      // Make the API request to update the user's decision
      const response = await axios.put(
        `https://applicationbackend.jalikoi.rw/api/v1/agent/${id}/decision`,
        decision
      );
      console.error(" approve user:", response);
      // Check if the response is successful
      if (response) {
        dispatch(fetchAsynAgents());
        setDrawerVisible(false);
      } else {
        console.error("Failed to approve user:", response.statusText);
      }
    } catch (error) {
      console.error("Error approving user:", error);
    }
  };

  const handleReject = async (id) => {
    try {
      // Prepare the data to send
      const decision = {
        decision: "REJECTED",
      };

      // Make the API request to update the user's decision

      const response = await axios.put(
        `https://applicationbackend.jalikoi.rw/api/v1/agent/${id}/decision`,
        decision
      );
      console.error(" rejected user:", response);
      // Check if the response is successful
      if (response) {
        dispatch(fetchAsynAgents());
        setDrawerVisible(false);

        // Optionally update UI or state after rejection
      } else {
        console.error("Failed to reject user:", response.statusText);
      }
    } catch (error) {
      console.error("Error rejecting user:", error);
    }
  };

  const paginatedData = allpaginatedApplication?.slice(startIndex, endIndex);

  useEffect(() => {
    // dispatch(fetchAsyncApplicantsApplications({ searchInput }));
    dispatch(fetchAsynAgents());
  }, [dispatch, searchInput]);

  useEffect(() => {
    if (hasAttemptedDownload) {
      if (isDownloading) {
        messageApi.open({
          type: "loading",
          content: "Downloading...",
          duration: 0,
        });
      } else if (downloadError) {
        messageApi.destroy();
        message.error("Download failed!");
      } else {
        messageApi.destroy();
        message.success("Download complete!");
      }
    }
  }, [isDownloading, downloadError, hasAttemptedDownload, messageApi]);

  return (
    <DashboardLayout>
      {contextHolder}
      <Spin spinning={isLoading} size="large" tip="Loading applicatants">
        <div className="mb-4">
          <input
            placeholder="Search by First name or Last name"
            value={searchQuery}
            onChange={handleSearch}
            className="w-full max-w-md"
          />
        </div>
        <table className="w-full text-left text-base text-gray-700 dark:text-gray-900 border">
          <thead className="text-xs text-gray-700 uppercase dark:text-gray-400 border-b">
            <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-black">
              <th scope="col" className="px-2 py-4">
                First Name
              </th>
              <th scope="col" className="px-2 py-4">
                Last Name
              </th>

              <th scope="col" className="px-2 py-4">
                Phone
              </th>
              <th scope="col" className="px-2 py-4">
                Created at
              </th>
              <th scope="col" className="px-2 py-4">
                Status
              </th>
              <th scope="col" className="px-2 py-4">
                Actions{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {alleAgentList &&
              alleAgentList.map((applicant) => {
                const createdDate = new Date(applicant.created_at);
                const isNew =
                  new Date().getTime() - createdDate.getTime() <
                  24 * 60 * 60 * 1000;
                const applicantStatus =
                  statuses[applicant.id] || (isNew ? "New" : "Old");
                return (
                  <tr
                    key={applicant.id}
                    className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-300 dark:hover:text-black"
                  >
                    <td className="border-r ">
                      <div className="capitalize pl-3">
                        {applicant.firstname}
                      </div>
                    </td>
                    <td className="px-2 border-r  ">
                      <div className="capitalize">{applicant.lastname}</div>
                    </td>

                    <td className="px-2 border-r  ">{applicant.phone}</td>
                    <td className="px-2 border-r  ">
                      {formatYYYYMMDD(applicant.created_at)}
                    </td>

                    <td className="px-2 border-r  ">
                      <span
                        className={`px-2 py-1 rounded text-sm ${
                          applicant.decision === "PENDING"
                            ? "text-white bg-yellow-700"
                            : applicant.decision === "APPROVED"
                            ? "text-white bg-green-700"
                            : applicant.decision === "REJECTED"
                            ? "text-white bg-red-700"
                            : "text-black bg-gray-300"
                        }`}
                      >
                        {applicant.decision}
                      </span>
                    </td>
                    <td className="px-2 border-r  ">
                      <div className="flex justify-around items-center">
                        {/* <FontAwesomeIcon
                          icon={faPenToSquare}
                          size="1x"
                          className="cursor-pointer text-[#23419B] mx-5"
                          onClick={() => handleEditRider(applicant)}
                        /> */}
                        <div
                          onClick={() => handleViewRider(applicant)}
                          className="px-3 cursor-pointer  rounded-md "
                        >
                          <FontAwesomeIcon
                            icon={faBookReader}
                            size="1x"
                            className="cursor-pointer text-[#23419B] "
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="flex justify-end bg-white p-3 rounded-md items-center mt-4">
          {allpaginatedApplication && (
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={allpaginatedApplication.length}
              onChange={handlePageChange}
            />
          )}
        </div>
      </Spin>

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <span>Edit Application Details</span>
            <div className="flex justify-end  py-4">
              <Button
                type="primary"
                onClick={() => saveChanges()}
                loading={triggerSubmission}
                className="bd-[#23419B] bg-[#23419B] hover:bg-[#23419B] hover:text-white"
              >
                Save Changes {triggerSubmission}
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={closeDrawer}
        visible={isEditMode}
        width={640}
      >
        <EditDrawer
          applicationDetails={applicationDetails}
          triggerSubmission={triggerSubmission}
          onSubmissionComplete={() => setTriggerSubmission(false)}
        />
      </Drawer>

      <Drawer
        title="Application Details"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        width={640}
      >
        {applicationDetails && (
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto flex flex-col">
            <div className="flex-grow ">
              {/* User details section */}
              <div className="space-y-4">
                <h2 className="text-2xl font-semibold text-gray-800">
                  User Details
                </h2>

                <div className="text-gray-600">
                  <p>
                    <strong className="font-medium">First Name:</strong>{" "}
                    {applicationDetails.firstname}
                  </p>
                  <p>
                    <strong className="font-medium">Last Name:</strong>{" "}
                    {applicationDetails.lastname}
                  </p>
                  <p>
                    <strong className="font-medium">Email:</strong>{" "}
                    {applicationDetails.email}
                  </p>
                  <p>
                    <strong className="font-medium">Phone:</strong>{" "}
                    {applicationDetails.phone}
                  </p>
                  <p>
                    <strong className="font-medium">Status:</strong>
                    <span
                      className={`font-bold ${
                        applicationDetails.decision === "PENDING"
                          ? "text-yellow-600"
                          : applicationDetails.decision === "APPROVED"
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {applicationDetails.decision}
                    </span>
                  </p>
                  <p>
                    <strong className="font-medium">Created At:</strong>{" "}
                    {new Date(applicationDetails.created_at).toLocaleString()}
                  </p>
                  <p>
                    <strong className="font-medium">Updated At:</strong>{" "}
                    {new Date(applicationDetails.updated_at).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>

            {/* Action buttons at the bottom */}
            <div className="flex justify-between gap-4 mt-3 ">
              {applicationDetails &&
                (applicationDetails.decision === "PENDING" ||
                  applicationDetails.decision === "REJECTED") && (
                  <button
                    className="bg-[#20409A] text-white px-6 py-1 cursor-pointer rounded-md hover:bg-[#20409A] transition duration-200 ease-in-out w-full md:w-auto"
                    onClick={() => handleApprove(applicationDetails.id)}
                  >
                    Approve
                  </button>
                )}
              <button
                className="bg-red-600 text-white px-6 py-1 cursor-pointer rounded-md hover:bg-red-700 transition duration-200 ease-in-out w-full md:w-auto"
                onClick={() => handleReject(applicationDetails.id)}
              >
                Reject
              </button>
            </div>
          </div>
        )}
      </Drawer>
    </DashboardLayout>
  );
}

export default JaliAgent;
