import React, { useState } from "react";
import { message, Modal } from "antd";
import main_logo from "../images/Groupeya_logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAgent } from "../../redux/transactions/UntappedSlice";
import axios from "axios";
import { motion } from "framer-motion";
import ProfileMenu from "./ProfileMenu";
import SuccessMessage from "../loanProcess/SuccessMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";


function LandingNavBar() {
  const dispatch = useDispatch();
  const loggedInAgent = useSelector(getAgent);

  console.log('loggedInAgent loggedin',loggedInAgent)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("login");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const showModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <nav className="relative border-b border-gray-600">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/jali-portal">
              <img src={main_logo} alt="logo" className="h-8 sm:h-10 w-auto" />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center justify-center space-x-8">
            <Link
              to="/jali-application-form"
              className="text-gray-400 hover:text-white transition-colors"
            >
              Application
            </Link>
            <Link
              to="/jali-application-agent"
              className="text-gray-400 hover:text-white transition-colors"
            >
              Agent
            </Link>
          </div>

          {/* Desktop Auth Buttons */}
          <div className="hidden md:flex items-center space-x-4">
            {!loggedInAgent ? (
              <>
                <button
                  onClick={() => showModal("login")}
                  className="text-white hover:text-gray-300 transition-colors"
                >
                  Login
                </button>
                <button
                  onClick={() => showModal("signup")}
                  className="border border-white text-white px-4 py-1 rounded-md hover:bg-white hover:text-gray-900 transition-colors"
                >
                  Register
                </button>
              </>
            ) : (
              <ProfileMenu loggedInAgent={loggedInAgent} />
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-gray-400 hover:text-white p-2"
            >
              <FontAwesomeIcon
                icon={isMobileMenuOpen ? faTimes : faBars}
                className="h-6 w-6"
              />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-gray-900 flex justify-between items-center">
         <div className="flex " >
         <Link
            to="/jali-application-form"
            className="block text-gray-400 hover:text-white py-2 px-3 rounded-md"
          >
            Application
          </Link>
          <Link
            to="/jali-application-agent"
            className="block text-gray-400 hover:text-white py-2 px-3 rounded-md"
          >
            Agent
          </Link>
         </div>
          <div>

          {!loggedInAgent && (
            <div className="space-y-2 pt-2">
              <button
                onClick={() => showModal("login")}
                className="block w-full text-left text-white hover:text-gray-300 py-2 px-3"
              >
                Login
              </button>
              <button
                onClick={() => showModal("signup")}
                className="block w-full text-left border border-white text-white px-3 py-2 rounded-md hover:bg-white hover:text-gray-900"
              >
                Register
              </button>
            </div>
          )}

          {loggedInAgent &&<ProfileMenu loggedInAgent={loggedInAgent} />}
          </div>
        </div>
      </div>

      {/* Modal for Login/Signup */}
      <Modal
        title={modalType === "signup" ? "Register as agent" : "Login"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={400}
      >
        {modalType === "signup" ? (
          <SignupForm onCancel={handleCancel} />
        ) : (
          <LoginForm onCancel={handleCancel} />
        )}
      </Modal>
    </nav>
  );
}



const SignupForm = ({ onCancel }) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email:'',
    password: "",
    confirm: "",
  });

  const [loading, setLoading] = useState(false);
  const [agentSent, setAgentSent] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (formData.password !== formData.confirm) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      await axios.post(
        "https://applicationbackend.jalikoi.rw/api/v1/agent/register",
        formData
      );
      setAgentSent(true);
    } catch (err) {
      message.error(err.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.form onSubmit={handleSubmit} className="">
      {!agentSent && (
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-400 mb-2">First Name</label>
            <input
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              className="w-full focus:outline-none text-black p-2 rounded-md"
              placeholder="Alex"
            />
          </div>
          <div>
            <label className="block text-gray-400 mb-2">Last Name</label>
            <input
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className="w-full focus:outline-none text-black p-2 rounded-md"
              placeholder="Doe"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-gray-400 mb-2">Phone</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full focus:outline-none text-black p-2 rounded-md"
              placeholder="Phone number"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-gray-400 mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full focus:outline-none text-black p-2 rounded-md"
              placeholder="ex: agent@gmail.com"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-gray-400 mb-2">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full focus:outline-none text-black p-2 rounded-md"
              placeholder="************"
            />
          </div>
          <div className="col-span-2">
            <label className="block text-gray-400 mb-2">Confirm Password</label>
            <input
              type="password"
              name="confirm"
              value={formData.confirm}
              onChange={handleChange}
              className="w-full focus:outline-none text-black p-2 rounded-md"
              placeholder="************"
            />
          </div>
          {error && <p className="text-red-500 col-span-2">{error}</p>}
          <button
            type="submit"
            className="w-full bg-[#20409A] text-white text-sm py-2 rounded-md hover:bg-gray-600 col-span-2"
            disabled={loading}
          >
            {loading ? "Processing..." : "Register Agent"}
          </button>
        </div>
      )}
      {agentSent && <SuccessMessage />}
    </motion.form>
  );
};




const LoginForm = ({ onCancel }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        "https://applicationbackend.jalikoi.rw/api/v1/agent/login",
        formData
      );
      const auth_user = response.data;
      localStorage.setItem("agent-Info", JSON.stringify(auth_user));
      window.location.replace("/jali-application-agent");

      onCancel();
    } catch (err) {
      message.error(err.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-gray-400 mb-2">Email</label>
        <input
          type="text"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full focus:outline-none text-black p-2 rounded-md"
          placeholder="email"
        />
      </div>
      <div>
        <label className="block text-gray-400 mb-2">Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="w-full focus:outline-none text-black p-2 rounded-md"
          placeholder="************"
        />
      </div>
      {error && <p className="text-red-500">{error}</p>}
      <button
        type="submit"
        className="w-full bg-[#20409A] text-white text-sm py-2 rounded-md hover:bg-gray-600"
        disabled={loading}
      >
        {loading ? "Processing..." : "Sign In"}
      </button>
    </motion.form>
  );
};






export default LandingNavBar;
