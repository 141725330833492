import { useState } from "react";
import { Switch, Tooltip } from "antd";

const ConfirmSection = ({ onConfirmChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
     // Send the updated state back to the parent
     if (onConfirmChange) {
      onConfirmChange(checked);
    }
  };

  return (
    <div className="mt-4 border p-4 rounded-md">
      <ol className="list-decimal pl-5 space-y-2 kwemeza-section">
        <li>Nemeza ko amakuru ntanze ari ukuri.</li>
        <li>
          Mpaye uburenganzira JALI FINANCE bwo kureba amakuru yanjye yose ajyanye
          n’inguzanyo mukigo gishinzwe ibyinguzanyo (Transunion Africa – CRB).
        </li>
        <li>
          Ndemerako ni nshaka gusubizwa amafaranga mbere yuko mpambwa moto
          nzayasubizwa havuyemo 10% byayo natanze nka kosiyo na avanse.
        </li>
        <li>
          Mpaye JALI FINANCE uburenganzira bwo gukoresha amakuru yanjye bwite
          muri gahunda zose zijyanye no kuba bangezaho serivise mbatse. Muribyo
          harimo ibijyanye no kubika ayo makuru cyangwa kuyasangiza n’ibigo
          bibakuriye.
        </li>
      </ol>
      <div className="mt-4 flex items-center">
        <Tooltip color='#469cac' placement="rightBottom" 
          title={
            isChecked
              ? "Wabyemeje neza, ushobora gukomeza."
              : "Emeza ko wemera ibi bisabwa kugira ngo ukomeze."
          } 
        >
          <Switch
            checked={isChecked}
            onChange={handleSwitchChange}
            checkedChildren="Wabyemeje neza"
            unCheckedChildren="Emeza hano"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ConfirmSection;
