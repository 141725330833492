import React from 'react';

const NoApplicationsSection = () => {
  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center space-y-4 animate-fade-in">
          <div className="relative">
            <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-blue-500/20 to-purple-500 opacity-20 blur animate-pulse" />
            <div className="relative bg-white dark:bg-blue-900  rounded-lg p-8 shadow-xl">
              <div className="flex flex-col items-center text-center space-y-4">
                <div className="p-3 bg-blue-100 dark:bg-blue-900/30 border rounded-full animate-bounce">
                  <svg 
                    className="w-8 h-8 text-blue-500 dark:text-blue-400"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                    <path d="M15 2H9a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1Z" />
                    <line x1="4" y1="18" x2="20" y2="18" />
                    <line x1="4" y1="14" x2="20" y2="14" />
                    <line x1="4" y1="10" x2="20" y2="10" />
                  </svg>
                </div>
                
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  No Applications Yet
                </h2>
                
                <p className="text-gray-600 dark:text-gray-300 max-w-md">
                  There are currently no agent applications available. New applications will appear here when they're submitted.
                </p>
                
               <a href='/jali-application-form' >
               <button className="mt-4 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors duration-200 transform hover:scale-105">
                Application form
                </button>
               </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoApplicationsSection;