import React, { useState, useEffect } from 'react';

function WifeIdentification({ data, onChangeWifeIdentifications }) {
  // Initialize state for each field
  const defaultFormData = {
    phoneNumber: '',
    firstName: '',
    middleName: '',
    lastName: '',
    idNumber: '',
    occupation: ''
  };
  

  const [formData, setFormData] = useState({defaultFormData});

  // Update state only if incoming `data` changes
  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(formData)) {
      setFormData({ ...defaultFormData, ...data });
    }
  }, [data]);

  // Notify parent only if `formData` changes
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (JSON.stringify(formData) !== JSON.stringify(data)) {
        onChangeWifeIdentifications(formData);
      }
    }, 500); // Debounce updates

    return () => clearTimeout(timeout);
  }, [formData, data, onChangeWifeIdentifications]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <form>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Nimero ya telefone</label>
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="078..."
          className="w-full focus:outline-none px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
        />
      </div>

      <div className="mb-4 grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium mb-2">Izina ribanza</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full px-3 focus:outline-none py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">Izina ryo hagati</label>
          <input
            type="text"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
            className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">Irindi zina</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Nimero y'irangamuntu</label>
        <input
          type="number"
          name="idNumber"
          value={formData.idNumber}
          onChange={handleChange}
          className="w-full px-3 py-2 focus:outline-none border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Icyo akora</label>
        <input
          type="text"
          name="occupation"
          value={formData.occupation}
          onChange={handleChange}
          placeholder="Icyo akora"
          className="w-full focus:outline-none px-3 py-2 border dark:border-gray-700 rounded-lg bg-gray-300 text-black"
        />
      </div>
    </form>
  );
}

export default WifeIdentification;
