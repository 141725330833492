import React, { useState } from "react";
import { Modal } from "antd";
import MotorcycleLoanShowcase from "./MotorcycleLoanShowcase ";
import main_logo from "../images/Groupeya_logo.png";
import { Link, useLocation } from "react-router-dom";
import LandingNavBar from "../navBar/LandingNavBar";

import { UserOutlined } from "@ant-design/icons";
import LandingFooter from "../navBar/LandingFooter";

const ProjectManagementApp = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-[#20409A] to-blue-900">
      {/* Navigation */}
      <LandingNavBar />

      {/* Main Content */}
      <div className="flex  flex-col items-center justify-around min-h-[80vh] w-full ">
        <MotorcycleLoanShowcase />
      </div>

      {/* Footer */}
     

      <LandingFooter />
    </div>
  );
};

export default ProjectManagementApp;
