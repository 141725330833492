import React, { useState, useEffect } from "react";
import { Button, message, Select, DatePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAsyncApplicantsApplications,
  loadingAlldata,
  getAllApplicantsApplications,
} from "../../../redux/transactions/UntappedSlice";

const { Option } = Select;

const EditDrawer = ({
  applicationDetails,
  triggerSubmission,
  onSubmissionComplete 
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    nid: "",
    status: "",
    driving_licency: "",
    driving_licency_issued_date: "",
    moto_leasing: null,
    insurerOne: {
      firstName: "",
      lastName: "",
      identity: "",
      gender: "",
      occupation: "",
      phone: "",
    },
    insurerTwo: {
      firstName: "",
      lastName: "",
      identity: "",
      gender: "",
      occupation: "",
      phone: "",
    },
  });

  const [motoLeasingOptions, setMotoLeasingOptions] = useState([]);
  useEffect(() => {
    fetch("https://applicationbackend.jalikoi.rw/api/v1/motoleasing")
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setMotoLeasingOptions(data); // Assuming the data is an array
      })
      .catch((error) => {
        console.error("Error fetching moto leasing data", error);
      });
  }, []);


  const saveChanges = async () => {
    console.log("formData", formData);
    onSubmissionComplete();
    // try {
    //   // Send a PATCH request with the form data
    //   const response = await axios.patch(
    //     `https://applicationbackend.jalikoi.rw/api/v1/application/applicant/${applicationDetails.id}`,
    //     formData
    //   );
    //   onSubmissionComplete();
    //   console.log("Response from server:", response.data);
    // } catch (error) {
    //     onSubmissionComplete();
    //   console.error("Error updating application:", error);
    // }
  };
// Use an effect to watch for the trigger
useEffect(() => {
    if (triggerSubmission) {
        saveChanges();
    }
  }, [triggerSubmission, onSubmissionComplete]);

  useEffect(() => {
    dispatch(fetchAsyncApplicantsApplications({ searchInput: "" }));
  }, [dispatch]);

  //   useEffect(() => {
  //     console.log("handleEditApplication", handleEditApplication);
  //     if (handleEditApplication) {
  //       console.log("formData", formData);
  //     }
  //   }, [handleEditApplication, formData]);

  useEffect(() => {
    if (applicationDetails) {
      setFormData({
        firstName: applicationDetails.first_name || "",
        lastName: applicationDetails.last_name || "",
        phone: applicationDetails.phone || "",
        nid: applicationDetails.nid || "",
        status: applicationDetails.status || "",
        driving_licency: applicationDetails.driving_licency || "",
        driving_licency_issued_date:
          applicationDetails.driving_licency_issued_date || "",
        moto_leasing: applicationDetails.moto_leasing.id || null,
        insurerOne: {
          firstName: applicationDetails.insurer_one?.first_name || "",
          lastName: applicationDetails.insurer_one?.last_name || "",
          identity: applicationDetails.insurer_one?.identity || "",
          gender: applicationDetails.insurer_one?.gender || "",
          occupation: applicationDetails.insurer_one?.occupation || "",
          phone: applicationDetails.insurer_one?.phone || "",
        },
        insurerTwo: {
          firstName: applicationDetails.insurer_two?.first_name || "",
          lastName: applicationDetails.insurer_two?.last_name || "",
          identity: applicationDetails.insurer_two?.identity || "",
          gender: applicationDetails.insurer_two?.gender || "",
          occupation: applicationDetails.insurer_two?.occupation || "",
          phone: applicationDetails.insurer_two?.phone || "",
        },
      });
    }
  }, [applicationDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("insurerOne.") || name.startsWith("insurerTwo.")) {
      const [insurer, field] = name.split(".");
      setFormData((prev) => ({
        ...prev,
        [insurer]: {
          ...prev[insurer],
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date, dateString) => {
    setFormData((prev) => ({
      ...prev,
      driving_licency_issued_date: dateString,
    }));
  };

  const handleMotoLeasingChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      moto_leasing: value,
    }));
  };

  return (
    <form className="bg-white rounded-lg shadow-md p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
        <h2 className="md:col-span-2 text-xl font-bold">
          Main Applicant Information
        </h2>
        {["firstName", "lastName", "phone", "nid", "status"].map((field) => (
          <div key={field} className="">
            <label className="block text-sm font-medium text-gray-700 capitalize">
              {field.replace(/([A-Z])/g, " $1")}
            </label>
            <input
              type="text"
              name={field}
              value={formData[field]}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            {/* {editFormDataErrors?.[field] && (
              <p className="text-red-500 text-sm mt-1">
                {editFormDataErrors[field]}
              </p>
            )} */}
          </div>
        ))}

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Driving License
          </label>
          <input
            type="text"
            name="driving_licency"
            value={formData.driving_licency}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {/* {editFormDataErrors?.driving_licency && (
            <p className="text-red-500 text-sm mt-1">
              {editFormDataErrors.driving_licency}
            </p>
          )} */}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Driving License Issued Date
          </label>
          <DatePicker
            name="driving_licency_issued_date"
            onChange={handleDateChange}
            value={
              formData.driving_licency_issued_date
                ? dayjs(formData.driving_licency_issued_date)
                : null
            }
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
          {/* {editFormDataErrors?.driving_licency_issued_date && (
            <p className="text-red-500 text-sm mt-1">
              {editFormDataErrors.driving_licency_issued_date}
            </p>
          )} */}
        </div>

        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Moto Leasing
          </label>
          <Select
            value={formData.moto_leasing}
            onChange={handleMotoLeasingChange}
            className="w-full"
            placeholder="Select Moto Leasing"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {motoLeasingOptions.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.type}
              </Option>
            ))}
          </Select>

          {/* {editFormDataErrors?.moto_leasing && (
            <p className="text-red-500 text-sm mt-1">
              {editFormDataErrors.moto_leasing}
            </p>
          )} */}
        </div>

        {/* Insurer One and Two fields */}
        {["insurerOne", "insurerTwo"].map((insurer) => (
          <div key={insurer} className="md:col-span-2 ">
            <h3 className="text-lg font-bold">{`${insurer.replace(
              /([A-Z])/g,
              " $1"
            )} Information`}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.keys(formData[insurer]).map((field) => (
                <div key={field} className="">
                  <label className="block text-sm font-medium text-gray-700 capitalize">
                    {field.replace(/([A-Z])/g, " $1")}
                  </label>
                  <input
                    type="text"
                    name={`${insurer}.${field}`}
                    value={formData[insurer][field]}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  {/* {editFormDataErrors?.[field] && (
                    <p className="text-red-500 text-sm mt-1">
                      {editFormDataErrors[field]}
                    </p>
                  )} */}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* <div className="flex justify-end w-full py-4">
        <button
          type="button"
          onClick={() => saveChanges()}
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
        >
          Save Changes
        </button>
      </div> */}
    </form>
  );
};

export default EditDrawer;
