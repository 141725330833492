import React, { useState, useRef, useEffect } from "react";
import { Avatar } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";

const ProfileMenu = ({ loggedInAgent }) => {
  // Dummy data
  const dummyUserInfo = {
    name: "Rugamba Benjamin",
    user_name: "ruisbenja",
    avatar: "/api/placeholder/80/80",
  };

  const dummyUserRole = ["administrator"];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  //   logout agent
  const logoutAgent = () => {
    localStorage.removeItem("agent-Info");
    window.location.replace("/jali-portal");
  };
  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hasAdminAccess =
    Array.isArray(dummyUserRole) &&
    (dummyUserRole.includes("administrator") ||
      dummyUserRole.includes("candidate_team"));

  // Removed process.env reference and using a hardcoded value for demo
  const murugoRegisterUrl = "/login";

  return (
    <div
      className="relative bg-white rounded-lg px-4 cursor-pointer w-full"
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      ref={dropdownRef}
    >
      {/* Profile Avatar and Name */}
      <div className="flex justify-between items-center py-1">
        <div className="flex flex-col">
          <h3 className="text-[12px] font-semibold text-[#20409A] profile_name">
            {loggedInAgent.firstname}
          </h3>
          <p className="text-[11px] text-[#819db9] mb-2">@agent</p>
        </div>
        <div className="flex justify-center items-center ">
          <div className="flex items-center px-1">
            {/* <Avatar  size="large"></Avatar> */}
            <Avatar
              shape="circle"
              size="large"
              className="flex justify-center items-center"
            >
              <div className="flex  items-center justify-center h-full w-full">
                <UserOutlined />
              </div>
            </Avatar>
          </div>
          <DownOutlined className="mx-1 h-[10px] w-[10px]" />
        </div>
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="absolute top-[115%] left-0 w-full z-50 bg-white border border-[#e6e6e6] p-4 rounded-b-lg shadow-lg">
          <div className="flex flex-col">
            <div className="flex justify-center items-center">
              <Avatar
                shape="square"
                size={64}
                className="flex justify-center items-center"
              >
                <div className="flex  items-center justify-center h-full w-full">
                  <UserOutlined />
                </div>
              </Avatar>
            </div>
            <div className="flex flex-col text-center  py-2">
              <h3 className="text-[12px] font-semibold text-[#20409A] capitalize">
                {loggedInAgent.firstname} {loggedInAgent.lastname}
              </h3>
              <p className="text-[11px] text-[#819db9] mb-2 ">
                @application agent
              </p>
            </div>
          </div>

          <ul className="list-none p-0 m-0 mb-4 border-t ">
            <li className="mt-2">
              <a
                href="/jali-application-form"
                className="text-[10px] text-[#819DB9] p-2.5 hover:bg-[#F5F7F8] hover:text-[#2C3E50] rounded block"
              >
                Application
              </a>
            </li>
            {loggedInAgent && (
              <li>
                <a
                  href="/jali-application-agent"
                  className="text-[10px] text-[#819DB9] p-2.5 hover:bg-[#F5F7F8] hover:text-[#2C3E50] rounded block"
                >
                  Agent Dashboard
                </a>
              </li>
            )}
          </ul>

          <div className="border-t">
            <ul className="list-none p-0 mt-2">
              <li>
                <span className="text-[10px] text-[#819DB9] p-2.5 hover:bg-[#F5F7F8] hover:text-[#2C3E50] rounded block">
                  Login with a different account
                </span>
              </li>
            </ul>

            <button
              onClick={logoutAgent}
              className="w-full py-2.5 px-4 bg-[#20409A] text-white rounded text-xs font-normal hover:bg-[#20409A]"
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
