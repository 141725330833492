import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardApi from "../../api/DashboardApi";
import axios from "axios";

const savedUser = localStorage.getItem("user");

const agentInfo = localStorage.getItem("agent-Info");

const apiUrl = "https://applicationbackend.jalikoi.rw/api/v1";
export const fetchAsyncAsset = createAsyncThunk(
  "untapped/assets",
  async ({ user_info, searchQuery, currentPage }) => {
    const response = await DashboardApi.get(
      `/assets/?page=${currentPage}&search=${searchQuery}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchAsyncRiders = createAsyncThunk(
  "untapped/riders",
  async (user) => {
    const response = await DashboardApi.get(
      `/riders/?page=${user.currentPage}&search=${user.searchQuery}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.user_info.access}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchAsyncAssigned = createAsyncThunk(
  "untapped/assets/assign",
  async ({ user_info, searchQuery, currentPage }) => {
    const response = await DashboardApi.get(
      `/assets/assign?page=${currentPage}&search=${searchQuery}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchAsyncPayment = createAsyncThunk(
  "untapped/rider/payment",
  async ({
    user_info,
    searchQuery,
    currentPage,
    firstDayOfWeek,
    lastDayOfWeek,
    lastDayOfMonth,
    firstDayOfMonth,
    selectedRange,
  }) => {
    if (firstDayOfWeek) {
      const response = await DashboardApi.get(
        `/riders/payment/history?page=${currentPage}&date_from=${firstDayOfWeek}&date_to=${lastDayOfWeek}&search=${searchQuery}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user_info.access}`,
          },
        }
      );
      return response.data;
    } else if (firstDayOfMonth) {
      const response = await DashboardApi.get(
        `/riders/payment/history?page=${currentPage}&date_from=${firstDayOfMonth}&date_to=${lastDayOfMonth}&search=${searchQuery}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user_info.access}`,
          },
        }
      );
      return response.data;
    } else if (selectedRange.length) {
      const response = await DashboardApi.get(
        `/riders/payment/history?page=${currentPage}&date_from=${selectedRange[0]}&date_to=${selectedRange[1]}&search=${searchQuery}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user_info.access}`,
          },
        }
      );
      return response.data;
    }
  }
);

export const fetchAsyncReceipts = createAsyncThunk(
  "untapped/rider/receipts",
  async ({
    user_info,
    selectedRange,
    currentPage,
    filterStatus,
    searchQuery,
  }) => {
    const response = await DashboardApi.get(
      `https://morideapi.groupeya.com/api/v1/riders/payment/receipt/?page=${currentPage}&date_from=${selectedRange[0]}&date_to=${selectedRange[1]}&search=${searchQuery}&payment_status_id=${filterStatus}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      }
    );

    return response.data;
  }
);

export const fetchAsyncLoggedinUser = createAsyncThunk(
  "untapped/user-accounts",
  async ({ user_info }) => {
    const response = await DashboardApi.get(`/accounts/edit/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(savedUser.access)}`,
      },
    });

    return response.data;
  }
);

// application list
export const fetchAsyncApplicantsApplications = createAsyncThunk(
  "applicatants/applications",
  async ({ searchInput }) => {
    const response = await axios.get(
      `${apiUrl}/application/search?query=${searchInput}`
    );
    const { data } = response;
    return data;
  }
);
// moto leasing
export const fetchAsyncMotoleasing = createAsyncThunk(
  "applicatants/motoleasing",
  async () => {
    const response = await axios.get(`${apiUrl}/motoleasing`);
    const { data } = response;

    return data;
  }
);

// get Provinces
export const fetchAsyncProvinces = createAsyncThunk(
  "location/provinces",
  async () => {
    const response = await axios.get(`${apiUrl}/provinces`);
    console.log("response", response);
    const { data } = response;
    return data;
  }
);

// get Districts
export const fetchAsyncDistricts = createAsyncThunk(
  "location/districts",
  async ({ province }) => {
    const response = await axios.get(
      `${apiUrl}/provinces/${province}/districts`
    );
    const { data } = response;
    console.log("districts", response);
    return data.districts;
  }
);

// Fetch Sectors
export const fetchAsyncSectors = createAsyncThunk(
  "location/sectors",
  async ({ district }) => {
    const response = await axios.get(`${apiUrl}/districts/${district}/sectors`);
    const { data } = response;

    return data.sectors;
  }
);

// Fetch Cells
export const fetchAsyncCells = createAsyncThunk(
  "location/cells",
  async ({ sector }) => {
    const response = await axios.get(`${apiUrl}/sectors/${sector}/cells`);
    const { data } = response;

    return data.cells;
  }
);

// Fetch Villages
export const fetchAsyncVillages = createAsyncThunk(
  "location/villages",
  async ({ cell }) => {
    const response = await axios.get(`${apiUrl}/cells/${cell}/villages`);
    const { data } = response;
    console.log("Villages", data.villages);
    return data.villages;
  }
);

// Fetch agents
export const fetchAsynAgents = createAsyncThunk(
  "registered/agent",
  async () => {
    const response = await axios.get(
      `https://applicationbackend.jalikoi.rw/api/v1/agent`
    );
    const { data } = response;
    console.log("agent", data);
    return data;
  }
);


// Fetch agent Applications 
export const fetchAsynagentApplications= createAsyncThunk(
  "applications/agent",
  async ({loggedInAgent}) => {

    console.log('logged In Agent',loggedInAgent.id)
    const response = await axios.get(
      `https://applicationbackend.jalikoi.rw/api/v1/agent/${loggedInAgent.id}/applicants`
    );
    const { data } = response;
    console.log("agent", data);
    return data;
  }
);
const initialState = {
  riderList: {},
  agentsList: null,
  agentApplicationsList: null,
  isLoadingAgents: false,
  motoleasingList: null,
  isLoadingmotoleasing: false,
  paginatedRiderList: {},
  assetList: [],
  ridersPayments: [],
  ridersPaymentsPaginatedList: [],
  ridersReceiptsList: [],
  paginatedRidersReceiptsList: [],
  paginatedassetLists: [],
  assignedAssetList: [],
  paginatedassignedAssetList: [],
  displayAsset: [],
  displayRider: [],
  isLoadingApplications: false,
  user: savedUser ? JSON.parse(savedUser) : null,
  agent: agentInfo ? JSON.parse(agentInfo) : null,

  loggedinuserInfo: null,
  applicatantsApplications: null,

  // locations
  provinces: [],
  districts: [],
  sectors: [],
  cells: [],
  villages: [],
  isLoadingProvinces: false,
  isLoadingDistricts: false,
  isLoadingSectors: false,
  isLoadingCells: false,
  isLoadingVillages: false,
};

const untappedSlice = createSlice({
  name: "untapped",
  initialState,
  reducers: {
    addRiders: (state, action) => {
      state.riderList = action.payload;
    },
  },
  extraReducers: {
    //agent Applications
    [fetchAsynagentApplications.pending]: (state) => {
      state.isLoadingAgentsApplication = true;
    },
    [fetchAsynagentApplications.fulfilled]: (state, { payload }) => {
      return { ...state, isLoadingAgentsApplication: false, agentApplicationsList: payload };
    },
    [fetchAsynagentApplications.rejected]: (state) => {
      state.isLoadingAgentsApplication = false;
    },
    // agents
    [fetchAsynAgents.pending]: (state) => {
      state.isLoadingAgents = true;
    },
    [fetchAsynAgents.fulfilled]: (state, { payload }) => {
      return { ...state, isLoadingAgents: false, agentsList: payload };
    },
    [fetchAsynAgents.rejected]: (state) => {
      state.isLoadingAgents = false;
    },
    // motor leasinhg
    [fetchAsyncMotoleasing.pending]: (state) => {
      state.isLoadingmotoleasing = true;
    },
    [fetchAsyncMotoleasing.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoadingmotoleasing: false,
        motoleasingList: payload,
      };
    },
    [fetchAsyncMotoleasing.rejected]: (state) => {
      state.isLoadingmotoleasing = false;
    },
    // Applicants Applications
    [fetchAsyncApplicantsApplications.pending]: (state) => {
      state.isLoadingApplications = true;
    },
    [fetchAsyncApplicantsApplications.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoadingApplications: false,
        applicatantsApplications: payload,
      };
    },
    [fetchAsyncApplicantsApplications.rejected]: (state) => {
      state.isLoadingApplications = false;
    },

    [fetchAsyncLoggedinUser.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncLoggedinUser.fulfilled]: (state, { payload }) => {
      return { ...state, isLoading: false, loggedinuserInfo: payload };
    },
    [fetchAsyncLoggedinUser.rejected]: (state) => {
      state.isLoading = false;
    },

    [fetchAsyncReceipts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncReceipts.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        paginatedRidersReceiptsList: payload.results,
        ridersReceiptsList: payload,
      };
    },
    [fetchAsyncReceipts.rejected]: (state) => {
      state.isLoading = false;
    },

    [fetchAsyncAsset.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncAsset.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        assetList: payload.results,
        paginatedassetLists: payload,
      };
    },
    [fetchAsyncAsset.rejected]: (state) => {
      state.isLoading = false;
    },

    [fetchAsyncRiders.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncRiders.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        riderList: payload.results,
        paginatedRiderList: payload,
      };
    },
    [fetchAsyncRiders.rejected]: (state) => {
      state.isLoading = false;
    },

    [fetchAsyncAssigned.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncAssigned.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        assignedAssetList: payload.results,
        paginatedassignedAssetList: payload,
      };
    },
    [fetchAsyncAssigned.rejected]: (state) => {
      state.isLoading = false;
    },

    [fetchAsyncPayment.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAsyncPayment.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        ridersPayments: payload.results,
        ridersPaymentsPaginatedList: payload,
      };
    },
    [fetchAsyncPayment.rejected]: (state) => {
      state.isLoading = false;
    },

    // Provinces
    [fetchAsyncProvinces.pending]: (state) => {
      state.isLoadingProvinces = true;
    },
    [fetchAsyncProvinces.fulfilled]: (state, { payload }) => {
      state.isLoadingProvinces = false;
      state.provinces = payload;
    },
    [fetchAsyncProvinces.rejected]: (state) => {
      state.isLoadingProvinces = false;
    },

    // Districts
    [fetchAsyncDistricts.pending]: (state) => {
      state.isLoadingDistricts = true;
    },
    [fetchAsyncDistricts.fulfilled]: (state, { payload }) => {
      state.isLoadingDistricts = false;
      state.districts = payload;
    },
    [fetchAsyncDistricts.rejected]: (state) => {
      state.isLoadingDistricts = false;
    },

    // Sectors
    [fetchAsyncSectors.pending]: (state) => {
      state.isLoadingSectors = true;
    },
    [fetchAsyncSectors.fulfilled]: (state, { payload }) => {
      state.isLoadingSectors = false;
      state.sectors = payload;
    },
    [fetchAsyncSectors.rejected]: (state) => {
      state.isLoadingSectors = false;
    },

    // Cells
    [fetchAsyncCells.pending]: (state) => {
      state.isLoadingCells = true;
    },
    [fetchAsyncCells.fulfilled]: (state, { payload }) => {
      state.isLoadingCells = false;
      state.cells = payload;
    },
    [fetchAsyncCells.rejected]: (state) => {
      state.isLoadingCells = false;
    },

    // Villages
    [fetchAsyncVillages.pending]: (state) => {
      state.isLoadingVillages = true;
    },
    [fetchAsyncVillages.fulfilled]: (state, { payload }) => {
      state.isLoadingVillages = false;
      state.villages = payload;
    },
    [fetchAsyncVillages.rejected]: (state) => {
      state.isLoadingVillages = false;
    },
  },
});

export const { addRiders } = untappedSlice.actions;
export const getAllRiders = (state) => state.untapped.riderList;
export const getAllpaginatedRiders = (state) =>
  state.untapped.paginatedRiderList;
export const getAllAsset = (state) => state.untapped.assetList;
export const getAllAssignedAsset = (state) => state.untapped.assignedAssetList;
export const getAllpaginatedAsset = (state) =>
  state.untapped.paginatedassetLists;

export const getAllridersPayments = (state) => state.untapped.ridersPayments;
export const getAllridersPaymentsPaginatedList = (state) =>
  state.untapped.ridersPaymentsPaginatedList;

export const getAllpaginatedAssignedAsset = (state) =>
  state.untapped.paginatedassignedAssetList;
// application list response
export const getAllApplicantsApplications = (state) =>
  state.untapped.applicatantsApplications;
export const loadingAlldata = (state) => state.untapped.isLoadingApplications;
// motoleassing
export const getAllMotoleasingList = (state) => state.untapped.motoleasingList;
export const isLoadingmotoleasing = (state) =>
  state.untapped.isLoadingmotoleasing;

// locations
export const getAllProvinces = (state) => state.untapped.provinces;
export const getAllDistricts = (state) => state.untapped.districts;
export const getAllSectors = (state) => state.untapped.sectors;
export const getAllCells = (state) => state.untapped.cells;
export const getAllVillages = (state) => state.untapped.villages;
export const isLoadingProvinces = (state) => state.untapped.isLoadingProvinces;
export const isLoadingDistricts = (state) => state.untapped.isLoadingDistricts;
export const isLoadingSectors = (state) => state.untapped.isLoadingSectors;
export const isLoadingCells = (state) => state.untapped.isLoadingCells;
export const isLoadingVillages = (state) => state.untapped.isLoadingVillages;
// agent information
export const getAgent = (state) => state.untapped.agent;
export const getAllAgent = (state) => state.untapped.agentsList;
export const isLoadingAgents = (state) => state.untapped.isLoadingAgents;

export const getAllAgentApplications = (state) => state.untapped.agentApplicationsList;
export const isLoadingAgentsApplication = (state) => state.untapped.isLoadingAgentsApplication;


export const getAllRidersReceipts = (state) =>
  state.untapped.ridersReceiptsList;
export const getAllPaginatedRidersReceipts = (state) =>
  state.untapped.paginatedRidersReceiptsList;
export const getUser = (state) => state.untapped.user;
export const getloggedinUser = (state) => state.untapped.loggedinuserInfo;

export default untappedSlice.reducer;
