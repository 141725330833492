import React from "react";
import {
  faLocationDot,
  faMessage,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LandingFooter() {
  return (
    <footer className="w-full p-4 bg-inherit">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 text-gray-400 text-sm">
          {/* Copyright - Always visible */}
          <div className="text-center sm:text-left">
            Copyright {new Date().getFullYear()} All Rights Reserved
          </div>

          {/* Contact Information */}
          <div className="flex flex-col sm:flex-row gap-4">
            <a href="#" className="hover:text-white flex items-center justify-center gap-2">
              <FontAwesomeIcon icon={faLocationDot} />
              <span>YIMA House, Sonatubes, Kigali</span>
            </a>
            <a href="mailto:info@jalifinance.com" className="hover:text-white flex items-center justify-center gap-2">
              <FontAwesomeIcon icon={faMessage} />
              <span>info@jalifinance.com</span>
            </a>
            <a href="tel:+250791901811" className="hover:text-white flex items-center justify-center gap-2">
              <FontAwesomeIcon icon={faPhone} />
              <span>(+250) 791 901 811</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default LandingFooter;